import React from 'react';
import { hydrate, render } from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/index.scss';
import './styles/icons.scss';
import './styles/buttons.scss';
import './styles/theme.scss';
import './styles/animation.scss';
import App from './App';
import configureStore from './store/configureStore.js'

const initApp = () => {
  const store = configureStore();
  const root = document.getElementById('root');
  const renderMethod = module.hot ? render : hydrate;
  renderMethod(
      <Provider store={ store }>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </Provider>,
    root
  );
};

initApp();