import React, { Suspense, lazy } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Preloader from "./components/blocks/Preloader/Preloader";
import { CATALOG_ADMIN } from "./constants/variables";
// import NotFound from '../src/components/NotFound'

const CommoPage = lazy(() => import('./components/pages/CommoPage'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const AdminPage = lazy(() => import('./components/pages/AdminPage'));
const NotFound = lazy(() => import('./components/NotFound'));

export const Routes = (props) => {
  const {aut, location, role, menu_sub, name_report} = props;
  if (role === CATALOG_ADMIN) {
    return (
      <Suspense fallback={<Preloader/>}> 
        <Switch>
          <Redirect exact from='/index.html' to='/admin/users' />
          <Redirect exact from='/admin' to='/admin/users' />
          <Route exact path='/admin/global' component={AdminPage} />
          <Route exact path='/admin/sources' component={AdminPage} />
          <Route exact path='/admin/users' component={AdminPage} />

          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    );
  } else {
    if (aut) {
      return (
        <Suspense fallback={<Preloader/>}> 
          <Switch>
          <Redirect exact from='/index.html' to='/stat/main' />
          <Redirect exact from='/' to='/stat/main' />
          <Redirect exact from='/login' to='/stat/main' />
          <Redirect exact from='/stat' to='/stat/main' />
            {/* <Route path="/stat" component={CommoPage />} /> */}
            <Route exact render={(props)=><CommoPage {...props} name_report={name_report} menu_sub={menu_sub}/>} path='/stat/main' />
            <Route exact render={(props)=><CommoPage {...props} name_report={name_report} menu_sub={menu_sub}/>} path='/stat/map' />
            <Route exact render={(props)=><CommoPage {...props} name_report={name_report} menu_sub={menu_sub}/>} path='/stat/analytics' />
            <Route exact render={(props)=><CommoPage {...props} name_report={name_report} menu_sub={menu_sub}/>} path='/stat/analytics/:name' />

            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      );
  } else {
      return (
        <Suspense fallback={<Preloader/>}> 
          <Switch>
              <Route path="/" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      )
  }
  }
}