export const THEME_MAIN = 'MAIN'; // Светлая тема
export const THEME_DARK = 'DARK'; // Темная тема

export const ALL = 'all'
export const CONTRACTS = 'contracts'
export const SERVICES = 'services'
export const UNAVAILABLE = 'unavailable'

export const adminMenu = ['users', 'sources']
//ключи для статусов
export const OK = 'ok'
export const ERROR = 'error'
//menu
export const GLOBAL = 'global'
export const USERS = 'users'
export const SOURCES = 'sources'
export const MAIN = 'main'
export const MAP = 'map'
export const ANALYTICS = 'analytics'

export const AUTHORIZATION_PAGE = 'authorization_page'
export const ADMIN_PAGE = 'admin_page'

export const AREA_CHART = 'area_chart'
export const BAR_CHART = 'bar_chart'
export const PIE_CHART = 'pie_chart'

export const DEFAULT_LOGO = 'logo_pritok.png'

export const TOTAL = 'total' 
export const DIVISION_NAME = 'division_name' 
export const PHYSICAL_PERSON = 'physical_person' 
export const JURIDICAL_PERSON = 'juridical_person' 

export const CONCLUDED = 'concluded'
export const CANCELLED = 'cancelled'
export const INCREMENT = 'increment'

export const URL = 'url'
export const NAME = 'name'
export const ADDRESS = 'address'
export const AUTHORIZATION_KEY = 'authorization_key'

export const FIO = 'fio'
export const LOGIN = 'login'
export const USERNAME = 'username'
export const PASSWORD = 'password'
export const NEW_PASSWORD = 'new_password'
export const REPORTS = 'reports'

export const DOGNUM = 'dognum'

//month
export const JAN = 'January'
export const FEB = 'February'
export const MAR = 'March'
export const APR = 'April'
export const MAY = 'May'
export const JUNE = 'June'
export const JULY = 'July'
export const AUG = 'August'
export const SEPT = 'September'
export const OCT = 'October'
export const NOV = 'November'
export const DEC = 'December'

export const CATALOG_ADMIN = 'admin'
export const CATALOG_CLIENT = 'stat'

export const PERIOD_TODAY = 1
export const PERIOD_WEEK = 2
export const PERIOD_MONTH = 3
export const PERIOD_YEAR = 4

export const UNAUTHORIZED = 401
export const FORBIDDEN = 403

export const TIME_UPDATE = 3600000

export const MAPITEM_FIZ = 1
export const MAPITEM_JUR = 2

//массив данных по объектам ответа на запрос /places-devices-objects состоит из
export const OBJ_LON = 0 //долгота
export const OBJ_LAT = 1 //широта
export const OBJ_CONTRACT = 2 //форма договора
export const OBJ_CUSTNUM = 3 //пультовый номер
export const OBJ_NAME_CARD = 4 //наименование карточки
export const OBJ_ADDRESS_CARD = 5 //адрес 

export const TIMER_VALUE = 1000;

export const MAIN_THEME_3 = 'blue'
export const MAIN_THEME_1 = 'gray'
export const MAIN_THEME_2 = 'burgundy'

export const GEO_UZ = 'data_geo_uz'
export const GEO_RU = 'data_geo_ru'
export const GEO_RU_REG = 'data_geo_ru_regions'
export const GEO_RU_EXT = 'data_geo_ru_extended'

export const FORM_CONTRACT = {
  'RU': {
    1: 'Физическое лицо',
    2: 'Юридическое лицо',
  },
  'UZ': {
    1: 'Jismoniy shaxs',
    2: 'Yuridik shaxs',
  }
}

export const THEME_COLORS = {
  [THEME_MAIN]: {
    'fill_polygon': '#fff',
    'fill_polygon_hover': '#6794dc',
    'color_polygon_hover': '#88b3ee',
    'line_polygon': '#bfbfbf',
  },
  [THEME_DARK]: {
    'fill_polygon': '#c4c4c4',
    'fill_polygon_hover': '#6794dc',
    'color_polygon_hover': '#88b3ee',
    'line_polygon': '#848484',
  }
}

export const HSL_SAT = 40;
export const HSL_LIGHT = 65;

export const REPORT_SERVICES = 'reports'
export const REPORT_CONTRACTS = 'reports_contracts'

export const ID_REPORT_COUNT_SERVICES = 100
export const ID_REPORT_COUNT_CONTRACTS = 200

//иды отчетов по услугам
export const ID_NUMBER_SERVICES = 1
export const ID_NUMBER_SERVICES_BY_MONTHS = 2
export const ID_NUMBER_SERVICES_PERIOD = 3
export const ID_NUMBER_SERVICES_PERIOD_PERSON = 4
export const ID_CARDS_SERVICES_PERIOD = 5
//иды отчетов по договорам
export const ID_NUMBER_CONTRACTS = 6
export const ID_NUMBER_CONTRACTS_BY_MONTHS = 7
export const ID_NUMBER_CONTRACTS_PERIOD = 8
export const ID_NUMBER_CONTRACTS_PERIOD_PERSON = 9
export const ID_CARDS_CONTRACTS_PERIOD = 10

//названия отчетов по услугам
export const NUMBER_SERVICES = 'number_services'
export const NUMBER_SERVICES_BY_MONTHS = 'number_services_by_months'
export const NUMBER_SERVICES_PERIOD = 'number_services_period'
export const NUMBER_SERVICES_PERIOD_PERSON = 'number_services_period_person'
export const CARDS_SERVICES_PERIOD = 'cards_services_period'
//названия отчетов по договорам
export const NUMBER_CONTRACTS = 'number_contracts'
export const NUMBER_CONTRACTS_BY_MONTHS = 'number_contracts_by_months'
export const NUMBER_CONTRACTS_PERIOD = 'number_contracts_period'
export const NUMBER_CONTRACTS_PERIOD_PERSON = 'number_contracts_period_person'
export const CARDS_CONTRACTS_PERIOD = 'cards_contracts_period'

export const IDSReportsServices = {
  [ID_NUMBER_SERVICES]: NUMBER_SERVICES,   
  [ID_NUMBER_SERVICES_BY_MONTHS]: NUMBER_SERVICES_BY_MONTHS,   
  [ID_NUMBER_SERVICES_PERIOD]: NUMBER_SERVICES_PERIOD,   
  [ID_NUMBER_SERVICES_PERIOD_PERSON]: NUMBER_SERVICES_PERIOD_PERSON,   
  [ID_CARDS_SERVICES_PERIOD]: CARDS_SERVICES_PERIOD,   
}
export const IDSReportsContracts = {  
  [ID_NUMBER_CONTRACTS]: NUMBER_CONTRACTS,   
  [ID_NUMBER_CONTRACTS_BY_MONTHS]: NUMBER_CONTRACTS_BY_MONTHS,  
  [ID_NUMBER_CONTRACTS_PERIOD]: NUMBER_CONTRACTS_PERIOD,  
  [ID_NUMBER_CONTRACTS_PERIOD_PERSON]: NUMBER_CONTRACTS_PERIOD_PERSON,  
  [ID_CARDS_CONTRACTS_PERIOD]: CARDS_CONTRACTS_PERIOD
}

export const SUB_REPORTS_ID = {
  [SERVICES]: 0,
  [CONTRACTS]: 1
}
export const ARRAY_SUB_REPORTS = [SERVICES, CONTRACTS]