import { ID_NUMBER_SERVICES, ID_NUMBER_SERVICES_BY_MONTHS, ID_NUMBER_SERVICES_PERIOD, ID_NUMBER_SERVICES_PERIOD_PERSON, ID_CARDS_SERVICES_PERIOD } from "../constants/variables";

export const reportData = [
    {
        "id": ID_NUMBER_SERVICES,
        "label_ru": "Количество услуг по договорам",
        "label_uz": "Shartnomalar bo'yicha xizmatlar soni"
    },
    {
        "id": ID_NUMBER_SERVICES_BY_MONTHS,
        "label_ru": "Изменение количества услуг по договорам за последние 6 месяцев",
        "label_uz": "So'nggi 6 oy ichida shartnomalar bo'yicha xizmatlar sonini o'zgartirish"
    },
    {
        "id": ID_NUMBER_SERVICES_PERIOD,
        "label_ru": "Изменение количества услуг по договорам за период",
        "label_uz": "Davr uchun shartnomalar bo'yicha xizmatlar sonining o'zgarishi"
    },
    {
        "id": ID_NUMBER_SERVICES_PERIOD_PERSON,
        "label_ru": "Изменение количества услуг по договорам за период по физическим и юридическим лицам",
        "label_uz": "Change in the number of services under contracts for the period for individuals and legal entities"
    },
    {
        "id": ID_CARDS_SERVICES_PERIOD,
        "label_ru": "Список карточек по заключенным договорам за период",
        "label_uz": "Davr uchun tuzilgan shartnomalar bo'yicha kartalar ro'yxati"
    }
]