import './LoadIndicator.scss'
import React from 'react';
/**
 * 
 */
const LoadIndicator = () => {
	let cl = 'LoadIndicator';
	return (
        <div className='load-container'>
            <div className={cl}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    );
};
export default LoadIndicator;