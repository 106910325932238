import { MAIN_THEME_2, MAIN_THEME_3 } from './constants/variables';

export default function initStyles(theme) {
	if (theme === MAIN_THEME_3) {
		import(
			/* webpackChunkName: "mainThemeBlue" */'./styles/main_theme_blue.scss'
		);
	} else if (theme === MAIN_THEME_2) {
		import(
			/* webpackChunkName: "mainThemeBurgundy" */'./styles/main_theme_burgundy.scss'
		);
	} else {
		import(
			/* webpackChunkName: "mainThemeGray" */'./styles/main_theme_gray.scss'
		);
	}
}
