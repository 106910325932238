import React from "react";
import { ContextApp } from "../context/ContextApp";
import { LanguageContext } from "../context/LanguageContext";
import { ThemeContext } from "../context/ThemeContext";

const ProvideAppContext = props => {
  return (
    <LanguageContext.Consumer>
      {language => (
        <ThemeContext.Consumer>
          {theme => (
            <ContextApp.Provider value={{ language, theme }}>
              {props.children}
            </ContextApp.Provider>
          )}
        </ThemeContext.Consumer>
      )}
    </LanguageContext.Consumer>
  );
};
export default ProvideAppContext;