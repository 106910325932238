import axios from 'axios';
/**
 * @param {String} url
 * @param {String} [method='GET']
 * @param {Object} params
 * Делает запрос с помощью Axios
 */
axios.defaults.headers.get['Content-Type'] = 'application/javascript; charset=UTF-8';
// axios.defaults.baseURL = `/api/`;
// let access_token = 'xxyyzz';
// axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}` 

export function request(url, params, method = 'GET', token, responseType, extra={}) {
  let APP_ACCESS_TOKEN = window.APP_ACCESS_TOKEN;
  let APP_SERVER = window.APP_SERVER;
  let APP_DOMEN = window.APP_DOMEN;
  if (!APP_ACCESS_TOKEN && !token) {
		return ;
	}
  const auth = token ? {} : {
    "Authorization" : `Bearer ${APP_ACCESS_TOKEN}`
  }
  let headers = extra && extra.load_image ? {
    'Content-Type': 'multipart/form-data',
  } : method === 'POST' || method === 'PUT' ? {
    ...auth,
		'X-Requested-With': 'XMLHttpRequest',
		'content-type': 'application/json',
		'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
  } : {
    ...auth
  };
  console.log('REQ', extra, APP_DOMEN);
	return axios({
      method: method,
      url: extra && extra.open_request ? `${APP_DOMEN}${url}` : `${APP_SERVER}${url}`,
      responseType,
      params: method === 'POST' || method === 'PUT' ? null : params,
      headers: headers,
      data: extra && extra.load_image ? params.data : method === 'POST' || method === 'PUT' ? JSON.stringify(params) : {},
      timeout: 1000 * 180,
      withCredentials: true
    })
    .then(response => {
        return response;
    })
    .catch(error => {
        return {error: {code: error.code, message: error.message, response: error.response}};
    });
}