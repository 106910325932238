import './styles/App.scss';
import React from 'react';
import { connect } from 'react-redux';
import { Routes } from './Routes';
import LogoBlock from './components/blocks/LogoBlock';
import Tabs from './components/modules/Tabs/Tabs';
import { getAuthUser, getGeoData, getGlobalInfo, getImage, getInfoUser, getMonth, getPeriod, getSources, getUsers, resetAuthUser, resetEnvironmentError, resetOnPage, saveScreenSize, setEnvironment } from './actions/actions';
import {adminMenu, ALL, ANALYTICS, CATALOG_ADMIN, CATALOG_CLIENT, CONTRACTS, ERROR, FORBIDDEN, GEO_RU, GLOBAL, IDSReports, IDSReportsContracts, IDSReportsServices, ID_CARDS_CONTRACTS_PERIOD, ID_CARDS_SERVICES_PERIOD, ID_NUMBER_CONTRACTS_PERIOD, ID_NUMBER_CONTRACTS_PERIOD_PERSON, ID_NUMBER_SERVICES_PERIOD, ID_NUMBER_SERVICES_PERIOD_PERSON, MAIN, MAIN_THEME_1, MAIN_THEME_3, MAP, NUMBER_SERVICES, OK, REPORT_CONTRACTS, REPORT_SERVICES, SERVICES, SOURCES, TRANSL, UNAUTHORIZED, UNAVAILABLE, USERS} from './constants/variables'
// import withRouter from '../src/components/withRouter'
import {withRouter} from 'react-router-dom'
import NavMenu from './components/blocks/NavMenu/NavMenu';
import { getLanguage, getSubMenu, getThemeApp, setLanguage, setSubMenu, setThemeApp } from './localStorage';
import { getLang, LanguageContext } from './context/LanguageContext';
import { getAppDomen, getAppServer, getAppToken, knockAction } from './js/common';
import { request } from './actions/request';
import Preloader from './components/blocks/Preloader/Preloader';
import LoadIndicator from './components/blocks/LoadIndicator/LoadIndicator';
import { BEM, roleUser } from './functions/globalFunctions';
import Modal from './components/modules/Modal/Modal';
import { sizes } from './js/media-query';
import { getTheme, ThemeContext } from './context/ThemeContext';
import ProvideAppContext from './providers/ProvideAppContext';
import initStyles from './initStyles';

class App extends React.Component {

  static propTypes = {
  }
  constructor(props) {
    super(props);
    this.state = {
      reciever: 0,
      open_menu: false,
      upload: true,
      show_modal_error: false
      // show_content: false
    };
		this.toogleTab = this.toogleTab.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.updateLanguage = this.updateLanguage.bind(this);
    this.toogleModalError = this.toogleModalError.bind(this);
    this.toogleTheme = this.toogleTheme.bind(this);
    this.onResize = this.onResize.bind(this);
    this.setAvailableReports = this.setAvailableReports.bind(this);
    // this.updatePage = this.updatePage.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    const {lang, theme} = this.props;

    getAppDomen()
    
    this.props.getGlobalInfo()
    .then((d) => {
      const {execution} = this.props;
      if (execution && execution['global_info'].status === OK) {
        initStyles(d.data.theme ? d.data.theme : MAIN_THEME_1);
        const NAME_GEO = d.data.name_geo_json;
        this.props.getGeoData(NAME_GEO)
      } else {
        initStyles(MAIN_THEME_1);
      }
    })
    this.props.getImage()
    
    const info_lang = getLanguage();
    const info_theme = getThemeApp();
      if (info_lang) {
        this.props.setEnvironment('lang', info_lang);
      } else {
        setLanguage(lang);
      }
    if (info_theme) {
      document.documentElement.dataset.theme = info_theme
      this.props.setEnvironment('theme', info_theme);
    } else {
      document.documentElement.dataset.theme = theme
      setThemeApp(theme);
    }
    
    const name = this.getScreenSizeClass()
    this.props.saveScreenSize(name)

    let APP = new Promise((resolve, reject) => {
      getAppServer(this.roleUser);
      if (this.roleUser === CATALOG_ADMIN) {
        this.setState((prevState) => { return {...prevState, 
          reciever: this.reciever
        }});
          if (this.tabs[this.reciever].name === USERS) {
            this.props.getUsers()
            .then(d => {
              if (this.props.execution && this.props.execution.users && this.props.execution.users.status === OK) {
                this.props.setEnvironment('menu', this.tabs[this.reciever].name )
                resolve(OK);
              }
            })
          } else {
            this.props.getSources()
            .then(d => {
              if (this.props.execution && this.props.execution.sources && this.props.execution.sources.status === OK) {
                this.props.setEnvironment('menu', this.tabs[this.reciever].name )
                resolve(OK);
              }
            })
          }

        }  else {
          getAppToken().then(ok => {
            if (ok) {
              this.props.getAuthUser(this.roleUser)
              .then(() => {
                resolve(OK);
              })
              
            } else {
              resolve(ERROR);
            }
            })
        }
    });  
    Promise.all([APP]).then(status => {
      this.setState({upload: false})
      if (status[0] === OK) {
        
        this.init();
      } else {
        this.props.resetAuthUser()
        window.history.pushState({}, undefined, '/login');
      }  
    }); 
 
  }
  componentWillUnmount() {
    this.props.saveScreenSize('');
    window.removeEventListener('resize', this.onResize);
  }
  onResize() {
    const name = this.getScreenSizeClass()
    this.props.saveScreenSize(name)
  }
  getScreenSizeClass() {
    const screenSizes = sizes();
    return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
  }
  init() {
    this.setState((prevState) => { return {...prevState, 
      reciever: this.reciever
    }});

    this.props.setEnvironment('menu', this.tabs[this.state.reciever].name )
    if (this.nameReport === ALL) {
      const menu_sub = getSubMenu()
      this.props.setEnvironment('menu_sub', menu_sub ? menu_sub : 'services'); 
      if (!menu_sub) setSubMenu('services')
    }
    this.props.setEnvironment('name_report', this.nameReport)
    this.setAvailableReports()

    this.props.getMonth();
    this.props.getPeriod();
  }
  componentDidUpdate(prevProps) {
    const {error_data} = this.props;
    if (error_data && error_data !== prevProps.error_data && error_data.text) {
      if (this.roleUser === CATALOG_ADMIN) {
        if (error_data.code !== 400 && error_data.code !== 409) this.setState({show_modal_error: true})
      } else {
        this.props.resetEnvironmentError()
        this.props.resetDatabase()
        this.props.resetAuthUser()
        window.history.pushState({}, undefined, '/login');
      }
    }
  }
  setAvailableReports() {
    //доступные отчеты
    const {user} = this.props;
    if (user && user.info && user.info.options) {
      const report_services = user.info.options[REPORT_SERVICES];
      const report_contracts = user.info.options[REPORT_CONTRACTS];
      console.log('setAvailableReports', report_services);
      const available_reports = {}
      if (report_services && report_services.length > 0) {
        Object.keys(IDSReportsServices).forEach(id => {
          console.log('IDSReportsServices1', report_services, id, report_services.indexOf(Number(id)) );
          if (report_services.indexOf(Number(id)) != -1) {
            console.log('IDSReportsServices', IDSReportsServices[id]);
            available_reports[`${IDSReportsServices[id]}`] = true
          }
        });
      }
      console.log('setAvailableReports858', report_contracts && report_contracts.length > 0 && report_contracts, IDSReportsContracts);
      if (report_contracts && report_contracts.length > 0) {
        Object.keys(IDSReportsContracts).forEach(id => {
          console.log('setAvailableReports88', id, report_contracts.indexOf(Number(id)));
          if (report_contracts.indexOf(Number(id)) != -1) {
            available_reports[`${IDSReportsContracts[id]}`] = true
          }
        });
      }
  
      this.props.setEnvironment('available_reports', available_reports)
    }
  }
  // updatePage() {
  //   this.toogleModalError()
  //   this.props.resetEnvironmentError()
  //   this.props.resetDatabase()
  //   knockAction()
  //   .then(() => {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 800)
  //   })
  // }
  get autUser() {
    const {user} = this.props;
    return user && user.info && user.info.hasOwnProperty('id');
  }
  get reciever() {
    const pathname = this.props.location.pathname;
    const ind_ = this.tabs.map(k => k.link).indexOf(pathname)
    const reciever = ind_ !== -1 ? ind_ : 0;
    return reciever;
  }
  get nameReport() {
    const {user} = this.props;
    const options = user && user.info && user.info.options;
    return options ? ((options[REPORT_SERVICES] && options[REPORT_CONTRACTS]) ? ALL : (options[REPORT_SERVICES] && !options[REPORT_CONTRACTS]) ? SERVICES : (!options[REPORT_SERVICES] && options[REPORT_CONTRACTS]) ? CONTRACTS : UNAVAILABLE) : undefined
  }
  get roleUser() {
    const {pathname} = this.props.location;
    return roleUser(pathname);
  }
  get transl() {
    const {lang} = this.props;
    return getLang(lang);
  }
  get tabs() {
    const {user} = this.props;
    const REPORT_ALL = this.nameReport === ALL;
    const items = this.roleUser === CATALOG_ADMIN ? [
      {
        name: USERS,
        text: this.transl.menu[USERS],
        link: `/admin/users`,
      }, {
        name: SOURCES,
        text: this.transl.menu[SOURCES],
        link: `/admin/sources`,
      },
      {
        name: GLOBAL,
        text: this.transl.menu[GLOBAL],
        link: `/admin/global`,
      },
    ] : [
      {
        name: MAIN,
        text: this.transl.menu[MAIN],
        link: `/stat/main`,
        sub: REPORT_ALL ? this.subMenuTabs : []
      }, 
      {
        name: MAP,
        text: this.transl.menu[MAP],
        link: `/stat/map`,
        sub: REPORT_ALL ? this.subMenuTabs : []
      },
    ]
    if (this.roleUser !== CATALOG_ADMIN && user && user.info && user.info.options && (user.info.options.reports || user.info.options.reports_contracts)) {
      if (user.info.options.reports ? (user.info.options.reports.indexOf(ID_NUMBER_SERVICES_PERIOD) != -1 || user.info.options.reports.indexOf(ID_NUMBER_SERVICES_PERIOD_PERSON) != -1 || user.info.options.reports.indexOf(ID_CARDS_SERVICES_PERIOD) != -1) : (user.info.options.reports_contracts.indexOf(ID_NUMBER_CONTRACTS_PERIOD) != -1 || user.info.options.reports_contracts.indexOf(ID_NUMBER_CONTRACTS_PERIOD_PERSON) != -1 || user.info.options.reports_contracts.indexOf(ID_CARDS_CONTRACTS_PERIOD) != -1)) {
        items.push(
          {
            name: ANALYTICS,
            text: this.transl.menu[ANALYTICS],
            link: `/stat/analytics`,
            sub: REPORT_ALL ? this.subMenuTabs : []
          }
        )
      }
    }
		return items;
	}
  get subMenuTabs() {
    return this.nameReport === ALL ? [
      {
        name: REPORT_SERVICES,
        text: 'Отчет по услугам',
      },
      {
        name: REPORT_CONTRACTS,
        text: 'Отчет по договорам',
      }
    ] : null
  }
  toogleModalError() {
    this.setState({show_modal_error: !this.state.show_modal_error})
    this.props.resetEnvironmentError()
  }
  toogleTab(k, k_sub) {
    const item = this.tabs[k];
    this.setState({reciever: k})
    console.log('toogleTab', k, k_sub);
    // this.context.history.push(`/admin/${adminMenu[k]}`);
    if (k_sub !== null && k_sub !== undefined) {
      this.props.setEnvironment('menu_sub', k_sub);
      setSubMenu(k_sub)
    } else {
      this.props.setEnvironment('menu_sub', 'services');
      setSubMenu('services')
    }
    this.props.setEnvironment('menu', item.name);
    window.history.pushState({}, undefined, item.link);
  }
  handleClickMenu(e) {
    this.setState({open_menu: !this.state.open_menu})
  }
  updateLanguage(value) {
    this.props.setEnvironment('lang', value);
    setLanguage(value);
  }
  toogleTheme(value) {
    this.props.setEnvironment('theme', value);
    setThemeApp(value);
    document.documentElement.dataset.theme = value
  }
  renderErrorPopup() {
    const {error_data} = this.props;
    const {show_modal_error} = this.state;
    // const actions = error_data.code === UNAUTHORIZED ? {
    //   onSave: this.updatePage
    // } : {}
    const unauthorized = error_data.code === UNAUTHORIZED;
    const forbidden = error_data.code === FORBIDDEN
    return (
      <Modal 
        isOpen={show_modal_error}
        onClose={unauthorized || forbidden ? null : this.toogleModalError} 
        title={this.transl['attention']}
      >
        {error_data.text}. {unauthorized ? `${this.transl['retry_authorization']}` : ''}
      </Modal>
    );
  }
  render() {
    const {lang, user, show_loader, theme, menu_sub, name_report, url_logo, global_info} = this.props;
    const {upload, show_modal_error} = this.state;
    return (
      <ThemeContext.Provider value={{
        theme, 
        switchTheme: this.toogleTheme, 
        currentData: getTheme(theme)
      }}>
      <LanguageContext.Provider value={{
        lang, 
        switchLang: this.updateLanguage, 
        currentData: this.transl
      }}>
        <ProvideAppContext>
        <div className={BEM('App', show_loader ? 'loading' : '')}>
            <header className="App-header">
              <div className='block-header'>
                <div className='App-header-name'>
                  
                  <div className='container-logo'>
                    <LogoBlock url={url_logo}/>
                    <div className='App-title'>
                      {global_info && global_info.name ? global_info && global_info.name : ''}
                    </div>
                  </div>

                  <NavMenu user={this.autUser ? user : null} roleUser={this.roleUser}/>

                </div>
                  {this.autUser || this.roleUser === CATALOG_ADMIN ? (<div className='App-navigation'>
                    <Tabs
                      items={this.tabs}
                      active={this.state.reciever}
                      onChange={this.toogleTab}
                    />
                  </div>) : null}
              </div>
            </header>
            <section className={`container`}>
              <div className='content-wrap'>
                {upload ? (
                  show_modal_error ? this.renderErrorPopup() :
                  <Preloader/>
                ) : (
                  <Routes menu_sub={menu_sub} name_report={name_report} aut={this.autUser} role={this.roleUser} location={this.props.location}/>
                )}
              {show_loader && <LoadIndicator />}
              {show_modal_error && this.renderErrorPopup()}
              </div>
            </section>
            <footer>
              <div className="copyright">
                <span className='text'>© 2022</span><a href="https://www.sokrat.ru/" target="_blank" rel="noopener noreferrer">ООО ОБ "Сократ"</a> 
                <span className='text'>
                &nbsp;|&nbsp;{process.env.REACT_APP_VERSION || "DEBUG"}
                  </span>
              </div>
            </footer>
        </div>
        </ProvideAppContext>
      </LanguageContext.Provider>
      </ThemeContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, env,  execution, database, fetched} = state;
  return { 
    user, 
    lang: env.lang, 
    theme: env.theme,
    name_report: env.name_report,
    show_loader: env.show_loader,
    execution,
    fetch_user: fetched['user_info'],
    error_data: env.error_msg,
    menu_sub: env.menu_sub,
    global_info: database.global_info,
    url_logo: database.url_logo
  };
};
const mapDispatchToProps = (dispatch) => ({
  setEnvironment: (key, data) => dispatch(setEnvironment(key, data)),
  getMonth: () => dispatch(getMonth()),
  getPeriod: () => dispatch(getPeriod()),
  getInfoUser: (id) => dispatch(getInfoUser(id)),
  getAuthUser: (role) => dispatch(getAuthUser(role)),
  getUsers: () => dispatch(getUsers()),
  getSources: () => dispatch(getSources()),
  resetAuthUser: () => dispatch(resetAuthUser()),
  resetEnvironmentError: () => dispatch(resetEnvironmentError()),
  resetDatabase: () => dispatch(resetOnPage('database')),
  saveScreenSize: (name) => dispatch(saveScreenSize(name)),
  getGeoData: (name_file_geo) => dispatch(getGeoData(name_file_geo)),
  getGlobalInfo: () => dispatch(getGlobalInfo()),
  getImage: () => dispatch(getImage()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
