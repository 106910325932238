/**
 * Производят манипуляции в store.database по заданному пути (action.path)
 * SET_IN_DATABASE_BY_PATH - присваивает значение action.data
 */
 export const SET_IN_DATABASE_BY_PATH = 'SET_IN_DATABASE_BY_PATH';
 export const REMOVE_IN_DATABASE_BY_PATH = 'REMOVE_IN_DATABASE_BY_PATH';
 export const APPEND_IN_DATABASE_BY_PATH = 'APPEND_IN_DATABASE_BY_PATH';
 export const UPDATE_IN_DATABASE_BY_PATH = 'UPDATE_IN_DATABASE_BY_PATH';
 /**
  * Переключают состояние в store для ключа (action.key)
  * @type {String}
  */
 export const START_FETCH = 'START_FETCH';
 export const STOP_FETCH = 'STOP_FETCH';
 
 export const RESET_DATABASE = 'RESET_DATABASE' 
 export const RESET_FETCHED = 'RESET_FETCHED' 
 
 //auth
 export const SET_AUTH_INFO = 'SET_AUTH_INFO'
 export const RESET_USER = 'RESET_USER'

 // environment
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT'
export const RESET_ENVIRONMENT = 'RESET_ENVIRONMENT' 

 // modals
export const SET_DISPLAY_MODAL = 'SET_DISPLAY_MODAL'
export const RESET_MODALS = 'RESET_MODALS'

export const  SUCCESSFUL_EXECUTION = 'SUCCESSFUL_EXECUTION';
export const RESET_EXECUTION = 'RESET_EXECUTION' 

export const SET_ENVIRONMENT_ERROR = 'SET_ENVIRONMENT_ERROR'
export const RESET_ENVIRONMENT_ERROR = 'RESET_ENVIRONMENT_ERROR'