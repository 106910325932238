import './Preloader.scss'
import React from 'react';
/**
 * 
 */
const Preloader = () => {
	let cl = 'Preloader';
	return (
        <div className={cl}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
export default Preloader;