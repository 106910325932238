import React from 'react'
import { THEME_DARK, THEME_MAIN } from "../constants/variables";

export const themes = {
	[THEME_MAIN]: {
		name: THEME_MAIN,
    },
    [THEME_DARK]: {
        name: THEME_DARK,
    }
}
export function getTheme(theme = '') {
    return themes[theme]
}
export const ThemeContext = React.createContext({ 
    theme: THEME_MAIN,
    currentData: themes[THEME_MAIN],
    switchTheme: () => {}, 
});

export const ThemeConsumer = ThemeContext.Consumer;