import './Modal.scss'
import React, {useContext} from 'react'
import Portal from '../Portal'
import { BEM } from '../../../functions/globalFunctions';
import Button from '../Button';
import { LanguageContext } from '../../../context/LanguageContext';

const c = 'prt-Modal';

const Modal = ({nameBtn, isOpen, children, style = {},  onClose, onSave, title}) => {
	const { currentData:CD } = useContext(LanguageContext);

	return isOpen ? (
		<Portal>
			<div className={c} style={style}>
				{onClose && <div className={BEM(c, 'overlay')} onClick={onClose}></div>}
				<div className={BEM(c, 'container')}>
				<div className={BEM(c, 'modal-top')}>
					{title && <div className={BEM(c, 'title')}>{title}</div>}
					{onClose && <div onClick={onClose} className={BEM(c, 'close')}>x<div className="dx-button-content"><i className="dx-icon dx-icon-close"></i></div></div>}
				</div>
				<div className={BEM(c, 'content')}>
					{children}
				</div>
				{onSave || onClose ? <div className={BEM(c, 'modal-bottom')}>
					<div style={{display: 'flex'}}>
						{onSave && <div style={{padding: '0 5px 0 0'}}>
							<Button
								action={onSave}
								type={"primary"}
								title={nameBtn ? (nameBtn === 'save' ? CD['save'] : CD['employ']) : CD['yes']}
							/>
						</div>}
						{onClose && <Button
							action={onClose}
							type={"secondary"}
							title={nameBtn ? (nameBtn === 'save' ? CD['cancel'] : CD['reset']) : (onSave ? CD['no'] : CD['close'])}
						/>}
					</div>
				</div>: null } 
				</div>
			</div>
		</Portal>
	) : null;
}

export default Modal
