export const monthData = [
    {
        "id": 1,
        "name": "Январь",
        "name_uz": "Yanvar",
    },
    {
        "id": 2,
        "name": "Февраль",
        "name_uz": "Fevral",
    },
    {
        "id": 3,
        "name": "Март",
        "name_uz": "Mart",
    },
    {
        "id": 4,
        "name": "Апрель",
        "name_uz": "Aprel",
    },
    {
        "id": 5,
        "name": "Май",
        "name_uz": "May",
    },
    {
        "id": 6,
        "name": "Июнь",
        "name_uz": "Iyun",
    },
    {
        "id": 7,
        "name": "Июль",
        "name_uz": "Iyul",
    },
    {
        "id": 8,
        "name": "Август",
        "name_uz": "Avgust",
    },
    {
        "id": 9,
        "name": "Сентябрь",
        "name_uz": "Sentyabr",
    },
    {
        "id": 10,
        "name": "Октябрь",
        "name_uz": "Oktyabr",
    },
    {
        "id": 11,
        "name": "Ноябрь",
        "name_uz": "Noyabr",
    },
    {
        "id": 12,
        "name": "Декабрь",
        "name_uz": "Dekabr",
    }
]

export const monthDataNormalize = {
    1: {
        "id": 1,
        "name_ru": "Январь",
        "name_uz": "Yanvar",
    },
    2: {
        "id": 2,
        "name_ru": "Февраль",
        "name_uz": "Fevral",
    },
    3: {
        "id": 3,
        "name_ru": "Март",
        "name_uz": "Mart",
    },
    4: {
        "id": 4,
        "name_ru": "Апрель",
        "name_uz": "Aprel",
    },
    5: {
        "id": 5,
        "name_ru": "Май",
        "name_uz": "May",
    },
    6: {
        "id": 6,
        "name_ru": "Июнь",
        "name_uz": "Iyun",
    },
    7: {
        "id": 7,
        "name_ru": "Июль",
        "name_uz": "Iyul",
    },
    8: {
        "id": 8,
        "name_ru": "Август",
        "name_uz": "Avgust",
    },
    9: {
        "id": 9,
        "name_ru": "Сентябрь",
        "name_uz": "Sentyabr",
    },
    10: {
        "id": 10,
        "name_ru": "Октябрь",
        "name_uz": "Oktyabr",
    },
    11: {
        "id": 11,
        "name_ru": "Ноябрь",
        "name_uz": "Noyabr",
    },
    12: {
        "id": 12,
        "name_ru": "Декабрь",
        "name_uz": "Dekabr",
    }
}