import './NavMenu.scss'
import React, { useRef, useContext } from "react";
import { useDetectClick } from "./useDetectClick";
import { ReactComponent as IconUser } from '../../../assets/icons/user.svg';
import { BEM } from '../../../functions/globalFunctions';
import DropdownMenu from '../../modules/DropdownMenu';
import { LanguageContext } from '../../../context/LanguageContext';
import { CATALOG_ADMIN, CATALOG_CLIENT, THEME_DARK, THEME_MAIN } from '../../../constants/variables';
import { deleteAppToken } from '../../../js/common';
import { ThemeContext } from '../../../context/ThemeContext';
import { ContextApp } from '../../../context/ContextApp';
// themeItem toogleTheme={toogleTheme}
const NavMenu = (props) => {
  const {user, roleUser} = props;
  const { currentData:CD } = useContext(ContextApp).language;

  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const [isActive, setIsActive] = useDetectClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const [isActive1, setIsActive1] = useDetectClick(dropdownRef1, false);
  const onClick1 = () => setIsActive1(!isActive1);

  const { switchLang, lang, currentData } = useContext(LanguageContext);
  const { switchTheme, theme } = useContext(ThemeContext);

  const onClickLang = (e) => {
    const value = e.target.innerText;
    switchLang(value)
    onClick1();
  }
  const toogleTheme = (e) => {
    const value = e.target;
    console.log(value);
    switchTheme(theme === THEME_DARK ? THEME_MAIN : THEME_DARK)
    onClick();
  }
  const logOut = () => {
    deleteAppToken().then(d => {
      window.location.reload();
    })
  }
  return (
    <div className='container-menu'>
        {user || roleUser === CATALOG_ADMIN ? <div className='link-menu'>
            <span onClick={onClick} className='icon-user'>
            <IconUser/>
            </span>
        {isActive &&
          (
            <div ref={dropdownRef}>
              <DropdownMenu themeItem toogleTheme={toogleTheme} valueTheme={theme === THEME_DARK} nameUser={user && user.info ? user.info.fio : CD['admin']} items={user && user.role === CATALOG_CLIENT ? [{name: currentData.exit, onClick: logOut}] : []}/>
            </div>
          )
        }
        </div> : null}
                <div className='link-menu'>
                <span onClick={onClick1} className={BEM('lang')}>
                  <span className={BEM('lang', 'txt')}>{lang}</span> 
                  <span className={BEM('lang', 'item-opened')}></span>
                </span>
                {isActive1 &&
                (
                  <div ref={dropdownRef1}>
                      <DropdownMenu id={lang} items={[{name: lang === 'RU' ? 'UZ' : 'RU', onClick: onClickLang}]}/>
                    </div>
                )}
              </div>
    </div>
  );
}

export default NavMenu;
