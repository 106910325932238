import {
	SET_IN_DATABASE_BY_PATH,
  APPEND_IN_DATABASE_BY_PATH,
  UPDATE_IN_DATABASE_BY_PATH,
  REMOVE_IN_DATABASE_BY_PATH,
  RESET_DATABASE,
  RESET_ENVIRONMENT,
  START_FETCH,
  STOP_FETCH,
	  RESET_FETCHED,
	SET_AUTH_INFO,
	SET_ENVIRONMENT,
	SET_DISPLAY_MODAL,
	RESET_MODALS,
	SUCCESSFUL_EXECUTION,
	SET_ENVIRONMENT_ERROR,
	RESET_ENVIRONMENT_ERROR,
	RESET_USER
} from './actionsTypes';
import { request } from "./request";
import {reportData} from '../data/reportsData'
import {reportsContractsData} from '../data/reportsContractsData'
import periodData from '../data/periodData.json'
import {monthData, monthDataNormalize} from '../data/monthData'
import { CATALOG_ADMIN, DEFAULT_LOGO, ERROR, GEO_RU, OK } from '../constants/variables';
import { getAppToken } from '../js/common';
import { getLanguage } from '../localStorage';
import { TRANSL } from '../context/LanguageContext';
import { dataParenthesesInArray, sortABC } from '../functions/globalFunctions';
import axios from 'axios';


const actionNameReset = {
	modals : RESET_MODALS,
	database: RESET_DATABASE,
	fetched: RESET_FETCHED,
	environment: RESET_ENVIRONMENT,
}

//authUser
export const setAuthUser = (info, role) => {
	return {
		type: SET_AUTH_INFO,
		info, role
	}
}
export const resetAuthUser = () => {
	return {
		type: RESET_USER,
	}
}

/*
 * успешность сохранения данных для запроса post
 * @param {Any} key путь для вставки
 * @param {String} status статус выполнения ERROR-ошибка|OK-готово
 * @param {String} text Путь для вставки
 * @param {String} name Путь для вставки
 * 
Количество договоров
url = `/number-contracts`;
Изменение количества договоров за последние 6 месяцев
url = `/number-contracts-by-months`;
Изменение количества договоров за период
url = `/number-contracts-period`;
Список карточек по заключенным договорам за период
url = `/cards-contracts-period`;
Количество приборов на охраняемых объектах
url = `/places-devices-objects-contracts`;

Количество услуг по договорам
url = `/number-services`;
Изменение количества услуг по договорам за последние 6 месяцев
url = `/number-services-by-months`;
Изменение количества услуг по договорам за период
url = `/number-services-period`;
Список карточек по заключенным договорам за период
url = `/cards-services-period`;
Количество приборов на охраняемых объектах
url = `/places-devices-objects-services`;
 * 
 */

export function successfulExecution(path, data) {
	return {
		type: SUCCESSFUL_EXECUTION,
		path, data
	};
}
export const setEnvironmentError = (data) => ({
    type: SET_ENVIRONMENT_ERROR,
    data
});
export const resetEnvironmentError = () => ({
    type: RESET_ENVIRONMENT_ERROR,
});

/* 
 * Очищает раздел redux по наименованию
 * @param {String} name наименование раздела
 */
export function resetOnPage(name) {
	return {
		type: actionNameReset[name]
	};
}
/*
 * Показ модального окна
 * @param {Any} params Данные
 * @param {String} modal_key название модалки
 * @param {Boolean} is_shown показ попапа
 */
//
export function setDisplayModal(modal_key, is_shown, params = {}) {
	return {
		type: SET_DISPLAY_MODAL,
		modal_key, is_shown, params
	};
}
//Environment
export const setEnvironment = (key, data) => {
	return {
	 type: SET_ENVIRONMENT,
	 key, data
	}
}
/*
 * Устанавливает значение поля в database
 * @param {Any} data Данные для вставки
 * @param {String} path Путь для вставки
 */
export function setInDatabaseByPath(data, path) {
	return {
		type: SET_IN_DATABASE_BY_PATH,
		data, path
	};
}
/*
 * Добавляет значение поля в database
 * @param {Any} data Данные для вставки
 * @param {String} path Путь для вставки
 * @param {Any} options доп параметры. если нужно вставлять в объект добавить {act: 'appendInObj'}
 */
//
export function appendInDatabaseByPath(data, path, options) {
	return {
		type: APPEND_IN_DATABASE_BY_PATH,
		data, path, options
	};
}
export function updateInDatabaseByPath(data, path, item, index) {
	return {
		type: UPDATE_IN_DATABASE_BY_PATH,
		data, path, item, index
	};
}
export function removeInDatabaseByPath(key, path) {
	return {
		type: REMOVE_IN_DATABASE_BY_PATH,
		key, path
	};
}
//загрузка данных
export const switchFetch = (key, value) => {
	return value ? {type: START_FETCH, key} : {type: STOP_FETCH, key};
}
/*
 * записать отредактированные данные database
 * @param {string} name_act название действия 'append'|'update'|'remove'|'set'
 * @param {string} path путь для вставки 
 * @param {integer} index_item индекс для вставки данных используется только для обновления если обновляется объект, то undefined
 * @param {any} item данные для вставки
 * @param {any} data весь массив данных используется только для обновления
 */
export function saveParamsDataBase(name_act, path, item, index_item, data) {
	return (dispatch) => {
		if (name_act === 'append') {
			dispatch(appendInDatabaseByPath(item, path))
		}
		if (name_act === 'remove') {
			dispatch(removeInDatabaseByPath(item, path))
		} 
		if (name_act === 'update') {
			dispatch(updateInDatabaseByPath(data, path, item, index_item))
		}
		if (name_act === 'set') {
			dispatch(setInDatabaseByPath(item, path))
		}
	}
}

/*
 * Вывод ошибки
 */
export function errorMessage(dispatch, path, d, type_action) {
	// console.log(d);
	const data_error = {}
    const lang = getLanguage();
	// d.response.data: "<HTML><BODY><B>500 Internal Server Error</B></BODY></HTML>"
		// d.request && d.request.status === 0 timeout 
		if (d && d.error && d.error.response && d.error.response.status === 400) {
			const statusText = d.error.response.statusText;
			const FIELDS = dataParenthesesInArray(statusText);
			data_error.text = `${TRANSL[lang].error['incorrect_data']}`;
			data_error.title = '';
			data_error.code = 400;
			data_error.fields = FIELDS.length > 0 ? FIELDS : [];
		} else if (d && d.error && d.error.response && d.error.response.status === 409) {
			const statusText = d.error.response.statusText;
			const FIELDS = dataParenthesesInArray(statusText);
			data_error.text = `${TRANSL[lang].error['user_exists']}`;
			data_error.title = '';
			data_error.code = 409;
			data_error.fields = FIELDS.length > 0 ? FIELDS : [];
		} else if (d && d.error && d.error.response && d.error.response.status === 401) {
			// 401
			data_error.text = `${TRANSL[lang].error['unauthorized']}`;
			data_error.title = 'Unauthorized';
			data_error.code = 401;
		} else if (d && d.error && d.error.response && d.error.response.status === 403) {
			// 403
			data_error.text = `${TRANSL[lang].error['forbidden']}`;
			data_error.title = 'Forbidden';	
			data_error.code = 403;
		} else {
			if (type_action === 'get') {
				if (d.response && d.response.status === 500) {
					data_error.text = `${TRANSL[lang].error['internal_server_error']}`;
					data_error.title = 'Internal Server Error'
					data_error.code = 500;
				} else if (d.error && d.error.code === 'ECONNABORTED' && d.error.message.indexOf('timeout') !== -1) {
					data_error.text = `${TRANSL[lang].error['econnaborted']}`;
				} else {
					data_error.text = `${TRANSL[lang].error['server_error']}`;
				}
			} else {
				data_error.text = `${TRANSL[lang].error['failed_execute']}`;
				data_error.title = 'Failed Execute'
				data_error.code = 0;
			}
		}
		dispatch(setEnvironmentError({
			text: data_error.text, 
			title: data_error.title,
			code: data_error.code ? data_error.code : null,
			baseURL: d.response && d.response.config && d.response.config.baseURL,
			url: d.response && d.response.config && d.response.config.url
		}));
		dispatch(successfulExecution(path, {
			status: ERROR, 
			text: data_error.text, 
			code: data_error.code ? data_error.code : null,
			fields: data_error.fields ? data_error.fields : []
		}))
}
/*
 * Отправка запроса GET
 * @param {String} url ссылка на запрос
 * @param {obj} params Параметры для оптравки в запрос
 * @param {String} path Путь для вставки в redux
 * @param {String} action set - установить/ add - добавить /set_user
 * @param {obj} options
 */
export function sendGetRequest(dispatch, url, params, path, token = false, action = 'set', options = {}) {
	dispatch(switchFetch(path, true));
	console.log('sendGetRequest', options);
	return request(url, params, 'GET', token, options && options.responseType ? options.responseType : undefined, options && options.extra ? options.extra : undefined)
	.then(d => {
		if (d && (d.status === 200 || d.status === 201 || d.status === 204) ) {
			const DATA = d.data.items ? d.data.items : d.data;
			const _data = options && options.sort && options.sort === 'abc' ? sortABC(DATA, 'division_name') : DATA;
			if (action === 'add') {
				dispatch(appendInDatabaseByPath(_data, path))
			} else {
				if (action === 'set_user') {
					dispatch(setAuthUser(_data, options && options.role))
				} else {
					if (options && options.add_count_increment) {
						const new_data = JSON.parse(JSON.stringify(_data));
						new_data.forEach(k => {
							k.physical_person.count_increment = Number(k.physical_person.count_concluded - k.physical_person.count_cancelled)
							k.juridical_person.count_increment = Number(k.juridical_person.count_concluded - k.juridical_person.count_cancelled)
						})
						dispatch(setInDatabaseByPath(new_data, path))
					} else if (options && options.add_total) {
						const new_data = JSON.parse(JSON.stringify(_data));
						new_data.forEach(k => {
							k.total = Number(k.count_juridical_person + k.count_physical_person)
						})
						dispatch(setInDatabaseByPath(new_data, path))
					} else {
						dispatch(setInDatabaseByPath(_data, path))
					}
					
				}
			}
			dispatch(switchFetch(path, false));
			dispatch(successfulExecution(path, {status: OK, text: '', code: null}))
			return d;
		} else {
			dispatch(switchFetch(path, false));
			errorMessage(dispatch, path, d, 'get');
		}
	})
}

export function setSnowLoader(value) {
	return (dispatch) => {
		dispatch(setEnvironment('show_loader', value))
	}
}

/*
 * Отправка запроса POST
 * @param {String} url ссылка на запрос
 * @param {String} action_name Имя фукнции для вставки
 * @param {obj} params Параметры для оптравки в запрос
 * @param {String} path_err Путь для вставки текста ошибки в redux
 */
export function sendPostRequest(dispatch, url, path, params, token = false, extra) {
	dispatch(setSnowLoader(true));
	return request(url, params, 'POST', token, undefined, extra).then(d => {
		dispatch(setSnowLoader(false));
		if (d && (d.status === 200 || d.status === 201 || d.status === 204) ) {
			if (d && d.data && d.data.error_msg) {
				dispatch(setSnowLoader(false));
				errorMessage(dispatch, path, d);
			} else {
				dispatch(successfulExecution(path, {status: OK, text: '', code: null}))
				return d.data;
			}
		} else {
			dispatch(setSnowLoader(false));
			errorMessage(dispatch, path, d);
		}
	});
}
export function sendPUTRequest(dispatch, url, path, params, token = false) {
	dispatch(setSnowLoader(true));
	return request(url, params, 'PUT', token).then(d => {
		dispatch(setSnowLoader(false));
		if (d && (d.status === 200 || d.status === 201 || d.status === 204) ) {
			if (d && d.data && d.data.error_msg) {
				dispatch(setSnowLoader(false));
				errorMessage(dispatch, path, d);
			} else {
				dispatch(successfulExecution(path, {status: OK, text: '', code: null}))
				return d.data;
			}
		} else {
			dispatch(setSnowLoader(false));
			errorMessage(dispatch, path, d);
		}
	});
}
export function sendDeleteRequest(dispatch, url, path, params, token = false) {
	dispatch(setSnowLoader(true));
	return request(url, params, 'DELETE', token).then(d => {
		dispatch(setSnowLoader(false));
		if (d && (d.status === 200 || d.status === 201 || d.status === 204) ) {
			if (d && d.data && d.data.error_msg) {
				dispatch(setSnowLoader(false));
				errorMessage(dispatch, path, d);
			} else {
				dispatch(successfulExecution(path, {status: OK, text: '', code: null}))
				return d.data;
			}
		} else {
			dispatch(setSnowLoader(false));
			errorMessage(dispatch, path, d);
		}
	});
}

export function setCountObjGeo(data) {
	return (dispatch) => {
		const path = `region_count`;
        dispatch(setInDatabaseByPath(data, path))
	}
}

//////////////////////////////////////////////
// ADMIN
//////////////////////////////////////////////
///global
export function getGlobalInfo() {
	return (dispatch) => {
		const url = `/global-info`;
		const path = `global_info`;
		return sendGetRequest(dispatch, url, undefined, path, true, 'set', {extra : {open_request: true}})
    }
}
export function postGlobalInfo(data) {
	return (dispatch) => {
		const url =`/save-global-info`;
		const path = 'save_global_info'
		return sendPostRequest(dispatch, url, path, data, true);
	}
}
//загрузка гео данных
export function getGeoJson() {
	return (dispatch) => {
		const url = `/data-geojson`;
		const path = `data_geojson`;
		return sendGetRequest(dispatch, url, undefined, path, true)
    }
}
export function addImage(data) {
	return (dispatch) => {
		const url =`/save-logo`;
		const path = 'save_logo'
		return sendPostRequest(dispatch, url, path, {data}, true, {load_image: true})
	}
}
// Удалить лого
export function deleteLogo() {
	return (dispatch) => {
		const url = `/save-logo`;
		const path = 'delete_logo'
		return sendDeleteRequest(dispatch, url, path, undefined, true);
	}
}

export function setImage(data) {
	return (dispatch) => {
		const path = `url_logo`;
        dispatch(setInDatabaseByPath(data, path))
	}
}
export function getImage() {
	return (dispatch) => {
		let APP_DOMEN = window.APP_DOMEN;
		const url_logo = `${APP_DOMEN}/logo`
		return axios.get(url_logo).then((response) => {
			const path = `url_logo`;
			return dispatch(setInDatabaseByPath(url_logo, path))
		}).catch((error) => {
			const path = `url_logo`;
			return dispatch(setInDatabaseByPath(`/img/${DEFAULT_LOGO}`, path))
		})
	}
}

///USERS
export function getInfoUser(id) {
	return (dispatch) => {
		const url = `/user/${id}`;
		const path = `user`;
		return sendGetRequest(dispatch, url, undefined, path, true)
    }
}
export function postAddUser(data) {
	return (dispatch) => {
		const url =`/user`;
		const path = 'add_user'
		return sendPostRequest(dispatch, url, path, data, true);
	}
}
export function putEditUser(id, data) {
	return (dispatch) => {
		const url =`/user/${id}`;
		const path = 'edit_user'
		return sendPUTRequest(dispatch, url, path, data, true);
	}
}
export function deleteUser(id) {
	return (dispatch) => {
		const url = `/user/${id}`;
		const path = 'delete_user'
		return sendDeleteRequest(dispatch, url, path, undefined, true);
	}
}

export function getUsers() {
	//200 OK
		// const items = [
		// 	{
		// 	  "id": 1,
		// 	  "fio": 'Иванов Иван Иванович',
		// 	  "username": 'ivanov',
		// 	  "origins": ['AC34F461-59E1-4168-93D0-3C41CD226A41'],
		// 	//   "origins": 'Подразделение 1',
		// 	  "options": {reports: [1]}
		// 	},
		// 	{
		// 	  "id": 2,
		// 	  "fio": 'Куликов Андрей Анатольевич',
		// 	  "username": 'kulic',
		// 	  "origins": ['AC34F461-59E1-4168-93D0-3C41CD226A42'],
		// 	//   "origins": 'Подразделение 2',
		// 	  "options": {reports: [2]}
		// 	},
		// ]
        // const path = `users`;
        // dispatch(setInDatabaseByPath(items, path))
		return (dispatch) => {
			const url = `/users`;
			const path = `users`;
			return sendGetRequest(dispatch, url, undefined, path, true)
    	}
}
export function getSources() {
	//200 OK
	return (dispatch) => {
		const url = `/origins`;
		const path = `sources`;
		return sendGetRequest(dispatch, url, undefined, path, true)
	}
	// return (dispatch) => {
	// 	const items = [
	// 		{
	// 			"id": 'AC34F461-59E1-4168-93D0-3C41CD226A41',
	// 			"title": 'Подразделение 1',
	// 			"url": 'hghgh/ggff',
	// 		},
	// 		{
	// 			"id": 'AC34F461-59E1-4168-93D0-3C41CD226A42',
	// 			"title": 'Подразделение 2',
	// 			"url": 'hghgh/kkkk',
	// 	  },
	// 	]
    //     const path = `sources`;
    //     dispatch(setInDatabaseByPath(items, path))
    // }
}
///ORIGINS
// GET /admin/origin/<id></id>
export function getInfoOrigin(id) {
	return (dispatch) => {
		const url = `/origin/${id}`;
		const path = `source`;
		return sendGetRequest(dispatch, url, undefined, path, true)
    }
}
export function postAddOrigin(data) {
	//201 Created
	return (dispatch) => {
		const url =`/origin`;
		const path = 'add_origin'
		return sendPostRequest(dispatch, url, path, data, true);
	}
}
export function putEditOrigin(data, id) {
	return (dispatch) => {
		const url =`/origin/${id}`;
		const path = 'edit_origin'
		return sendPUTRequest(dispatch, url, path, data, true);
	}
}
export function deleteOrigin(id) {
	return (dispatch) => {
		const url = `/origin/${id}`;
		const path = 'delete_origin'
		return sendDeleteRequest(dispatch, url, path, undefined, true);
	}
}
//////////////////////////////////////////////


export function getReports() {
	return (dispatch) => {
			const path = `reports`;
			const path2 = `reports_contracts`;
			dispatch(setInDatabaseByPath(reportData, path))
			dispatch(setInDatabaseByPath(reportsContractsData, path2))
    }
}
export function getMonth() {
	return (dispatch) => {
			const path = `month`;
			dispatch(setInDatabaseByPath(monthData, path))
			const path2 = `month_norm`;
			dispatch(setInDatabaseByPath(monthDataNormalize, path2))
    }
}
export function getPeriod() {
	return (dispatch) => {
			const path = `period`;
			dispatch(setInDatabaseByPath(periodData, path))
    }
}

////ОТЧЕТЫ ПО ДОГОВОРАМ//////
// Количество договоров
export function getReportNumberContracts() {
	return (dispatch) => {
		const path = `report.number_contracts`;
		const url = `/number-contracts`;
		return sendGetRequest(dispatch, url, undefined, path, false, 'set', {add_total: true, sort: 'abc'})
    }
}
// Изменение количества договоров за последние 6 месяцев
export function getReportNumberContractsMonths() {
	return (dispatch) => {
		const path = `report.number_contracts_by_months`;
		const url = `/number-contracts-by-months`;
		return sendGetRequest(dispatch, url, undefined, path)
    }
}

// Изменение количества договоров за период
export function getReportNumberContractsPeriod(period={}) {
	return (dispatch) => {
		const path = `report.number_contracts_period`;
		const url = `/number-contracts-period`;
		return sendGetRequest(dispatch, url, period, path, false, 'set', {add_count_increment: true, sort: 'abc'})
	}
}

// Список карточек по заключенным договорам за период
export function	getReportCardsContracts(period={}) {
	return (dispatch) => {
		const path = `report.cards_contracts_period`;
		const url = `/cards-contracts-period`;
		return sendGetRequest(dispatch, url, period, path, false, 'set', {sort: 'abc'})
	}
}

// Количество приборов на охраняемых объектах
export function getPlacesDevicesObjectsContracts() {
	return (dispatch) => {
		const path = `map.places_devices_objects_contracts`;
		const url = `/places-devices-objects-contracts`;
		return sendGetRequest(dispatch, url, undefined, path, false, 'set', {sort: 'abc'})
	}
}
//////

////ОТЧЕТЫ ПО УСЛУГАМ//////
// Количество услуг по договорам
export function getReportNumberServices() {
	// report/number-services-contracts
	return (dispatch) => {
		// const items = {
		// 	items: [
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A41',
		// 		"division_name": 'Подразделение 1',
		// 		"count_physical_person": 100,
		// 		"count_juridical_person": 113,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A42',
		// 		"division_name": 'Подразделение 2',
		// 		"count_physical_person": 200,
		// 		"count_juridical_person": 213,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A43',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A44',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A45',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A46',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A47',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A48',
		// 		"division_name": 'Подразделение 3',
		// 		"count_physical_person": 150,
		// 		"count_juridical_person": 80,
		// 	}
		// ]}
        const path = `report.number_services`;
        // dispatch(setInDatabaseByPath(items, path))
		const url = `/number-services`;
		return sendGetRequest(dispatch, url, undefined, path, false, 'set', {add_total: true, sort: 'abc'})
    }
}

// Изменение количества услуг по договорам за последние 6 месяцев
export function getReportNumberServicesMonths() {
	// report/number-services-contracts-by-months
	return (dispatch) => {
		// const items = {
		// 	items: [
		// 		{
					
		// 			"month": 5,
		// 			"count_physical_person": 100,
		// 			"count_juridical_person": 113
		// 		},
		// 		{
					
		// 			"month": 6,
		// 			"count_physical_person": 156,
		// 			"count_juridical_person": 200
		// 		},
		// 		{
					
		// 			"month": 7,
		// 			"count_physical_person": 220,
		// 			"count_juridical_person": 120
		// 		},
		// 		{
					
		// 			"month": 8,
		// 			"count_physical_person": 120,
		// 			"count_juridical_person": 113
		// 		},
		// 		{
					
		// 			"month": 9,
		// 			"count_physical_person": 250,
		// 			"count_juridical_person": 222
		// 		},
		// 		{
					
		// 			"month": 10,
		// 			"count_physical_person": 100,
		// 			"count_juridical_person": 150
		// 		}
		// 	],
		// 	comment: [
		// 		{
		// 			"text": '',
		// 			"content": ''
		// 		}
		// 	]
		// }
        const path = `report.number_services_by_months`;
        // dispatch(setInDatabaseByPath(items, path))
		const url = `/number-services-by-months`;
		return sendGetRequest(dispatch, url, undefined, path)
    }
}

// Изменение количества услуг по договорам за период
export function getReportNumberServicesPeriod(period={}) {
	// /report/number-services-contracts-period
	return (dispatch) => {
		// const items = {
		// 	items: [
		// 	{
		// 	  "id": 'AC34F461-59E1-4168-93D0-3C41CD226A41',
		// 	  "division_name": 'Подразделение 1',
		// 	  "physical_person": {
		// 		"count_concluded": 4,
		// 		"count_cancelled": 1,
		// 	  },
		// 	  "juridical_person": {
		// 		"count_concluded": 6,
		// 		"count_cancelled": 1,
		// 	  }
		// 	},
		// 	{
		// 	  "id": 'AC34F461-59E1-4168-93D0-3C41CD226A42',
		// 	  "division_name": 'Подразделение 2',
		// 	  "physical_person": {
		// 		"count_concluded": 10,
		// 		"count_cancelled": 0,
		// 	  },
		// 	  "juridical_person": {
		// 		"count_concluded": 10,
		// 		"count_cancelled": 0,
		// 	  }
		// 	},
		// ]}
        const path = `report.number_services_period`;
        // dispatch(setInDatabaseByPath(items, path))
		const url = `/number-services-period`;
		return sendGetRequest(dispatch, url, period, path, false, 'set', {add_count_increment: true, sort: 'abc'})
    }
}

// Список карточек по заключенным договорам за период
export function getReportCardsServices(period={}) {
	// /report/cards-contracts-period
	return (dispatch) => {
		// const items = {
		// 	items: [
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A41',
		// 		"division_name": 'Подразделение 1',
		// 		"date": 1399993200,
		// 		"custclass": 'Аптека',
		// 		"custname": 'Аптека Здоровье'
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A42',
		// 		"division_name": 'Подразделение 2',
		// 		"date": 1399993200,
		// 		"custclass": 'Аптека',
		// 		"custname": 'Аптека Здоровье'
		// 	},
		// 	{
		// 		"id": 'AC34F461-59E1-4168-93D0-3C41CD226A43',
		// 		"division_name": 'Подразделение 3',
		// 		"date": 1399993200,
		// 		"custclass": 'Квартира',
		// 		"custname": 'Квартира Исломова Петра Ивановича'
		// 	}
		// ]}
        const path = `report.cards_services_period`;
        // dispatch(setInDatabaseByPath(items, path))
		const url = `/cards-services-period`;
		return sendGetRequest(dispatch, url, period, path, false, 'set', {sort: 'abc'})
    }
}

// Количество приборов на охраняемых объектах
export function getPlacesDevicesObjectsServices() {
	// /report/places-devices-objects
	return (dispatch) => {
		// const items = {
		// 	items: [
		// 	{
		// 		"division_name": 'Подразделение 1',
		// 		"coord": [[41.377491, 64.585262], [41.2646, 69.2163], [41.26521, 69.2163]],
		// 		"info": {}
		// 	},
		// 	{
		// 		"division_name": 'Подразделение 2',
		// 		"coord": [[64.3311, 38.9858]],
		// 		"info": {}
		// 	},
		// ]}
        const path = `map.places_devices_objects_services`;
        // dispatch(setInDatabaseByPath(items, path))
		const url = `/places-devices-objects-services`;
		return sendGetRequest(dispatch, url, undefined, path, false, 'set', {sort: 'abc'})
    }
}

export function getGeoData(name_file_geo) {
	return (dispatch) => {
        const path = `map.data_geo`;
		if (name_file_geo) {
			return axios.get(`/data/${name_file_geo}.json`)
			.then(response => {
				dispatch(setInDatabaseByPath(response.data, path))
		})
		} else {
			dispatch(setInDatabaseByPath({}, path))
		}
		
		// const url = `/data-geojson`;
		// return sendGetRequest(dispatch, url, undefined, path, false)
    }
}
////////


export function getAuthUser(role) {
	return (dispatch) => {
		const url = `/user-info`;
		const path = 'user_info'
		return sendGetRequest(dispatch, url, undefined, path, false, 'set_user', {role: role ? role : ''})
    }
}

export function setCountObj(count_obj) {
	return (dispatch) => {
        const path = `count_obj`;
        dispatch(setInDatabaseByPath(count_obj, path))
    }
}
//данные media
export function saveScreenSize(name) {
    return (dispatch) => {
        const path = `media`;
        dispatch(setEnvironment(path, {name}))
    }
}
