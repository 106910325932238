import { ID_NUMBER_CONTRACTS, ID_NUMBER_CONTRACTS_BY_MONTHS, ID_NUMBER_CONTRACTS_PERIOD, ID_NUMBER_CONTRACTS_PERIOD_PERSON, ID_CARDS_CONTRACTS_PERIOD } from "../constants/variables";

export const reportsContractsData = [
    {
        "id": ID_NUMBER_CONTRACTS,
        "label_ru": "Количество договоров",
        "label_uz": "Shartnomalar soni"
    },
    {
        "id": ID_NUMBER_CONTRACTS_BY_MONTHS,
        "label_ru": "Изменение количества договоров за последние 6 месяцев",
        "label_uz": "So'nggi 6 oy ichida shartnomalar sonining o'zgarishi"
    },
    {
        "id": ID_NUMBER_CONTRACTS_PERIOD,
        "label_ru": "Изменение количества договоров за период",
        "label_uz": "Davr uchun shartnomalar sonining o'zgarishi"
    },
    {
        "id": ID_NUMBER_CONTRACTS_PERIOD_PERSON,
        "label_ru": "Изменение количества договоров за период по физическим и юридическим лицам",
        "label_uz": "Jismoniy va yuridik shaxslar uchun davr uchun shartnomalar sonining o'zgarishi"
    },
    {
        "id": ID_CARDS_CONTRACTS_PERIOD,
        "label_ru": "Список карточек по заключенным договорам за период",
        "label_uz": "Davr uchun tuzilgan shartnomalar bo'yicha kartalar ro'yxati"
    }
]