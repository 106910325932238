import React, { useRef } from "react";
import Toggler from "./Toggler";

export default function DropdownMenu({nameUser, items, themeItem, toogleTheme, valueTheme}) {
  return (
    <nav className="DropdownMenu">
        {nameUser && <p>{nameUser}</p>}
        {themeItem && (
          <Toggler handleChange={toogleTheme} value={valueTheme} title="Тёмная тема" />
        )}
        <ul>
            {items && items.length > 0 && items.map((k, key) => {
                return (
                    <li key={key}><span className='link' onClick={k.onClick}>{k.name}</span></li>
                );
            })}
        </ul>
    </nav>
  );
}
