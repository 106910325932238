import { CATALOG_ADMIN, CATALOG_CLIENT, HSL_LIGHT, HSL_SAT, PERIOD_MONTH, PERIOD_TODAY, PERIOD_WEEK, PERIOD_YEAR } from "../constants/variables";

export const canUseDOM = () => !!(typeof window !== 'undefined' && window.document);

export function isEmptyValue(value) {
	return (typeof value === "undefined" || value === null || value ===  "");
}
export function isEmpty(n) {
	return !(!!n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
}

// export function isImage(url) {
//     let resultCheck = true;
//     image = document.createElement('img');
//     image.src = url;
//     image.onerror = function() {
//         let resultCheck = false;
//     };
//     return resultCheck;
// }

export const arrayFilterKeys = (data, keys) => {
    return data.filter(k => {
        return keys.includes(k.id)
    })
}

export const roleUser = (pathname) => {
    return pathname.includes(CATALOG_ADMIN) ? CATALOG_ADMIN : CATALOG_CLIENT;
}

export function BEM(block = '', element = '', modificators = [], extra) {
	const name = block + (element ? ('__' + element) : '');
	return name +
		' ' + modificators.map(i => i ? `${name}--${i}` : '').join(' ')
		+ (extra ? (' ' + extra) : '');
}

export function sumCount(list = []) {
    const sum = {};

    list.map((item) => {
        Object.entries(item).map(([key, val]) => {
            if (typeof val === 'object') {
                Object.entries(val).map(([key, val]) => {
                    if (typeof (val) === 'number') {
                        sum[key] = sum[key] ? (sum[key] + val) : val;
                    }
                })
            } else {
                if (typeof (val) === 'number') {
                    sum[key] = sum[key] ? (sum[key] + val) : val;
                }
            }
        })
    });
    return sum;
}
export function sumCountPhysJur(list = []) {
    const physical_person = {}
    const juridical_person = {}

    list.map((item) => {
        if (item['physical_person']) {
            Object.entries(item['physical_person']).map(([key, val]) => {
            if (typeof (val) === 'number') {
                physical_person[key] = physical_person[key] ? (physical_person[key] + val) : val
                }
            })
        }
        if (item['juridical_person']) {
            Object.entries(item['juridical_person']).map(([key, val]) => {
            if (typeof (val) === 'number') {
                juridical_person[key] = juridical_person[key] ? (juridical_person[key] + val) : val
                }
            })
        }
        
    });

    return {physical_person, juridical_person};
}
export function createArrayObj(obg, exclusion) {
    const myArray = Object.entries(obg).map(([k, v]) => {
        if (exclusion.includes(k)) return ;
        return {name: k, value: v}
    });
    return myArray;
} 
export function createArrayDia(array, name, value, count_physical_person, count_juridical_person,options) {
    const myArray = []
    array.forEach(k => {
        myArray.push({
            name: k[name], 
            value: k[value], 
            count_physical_person: k[count_physical_person], 
            count_juridical_person: k[count_juridical_person],
            color: k[options] && k[options].color && k[options].color
        })
     })
    return myArray;
}

export const getDate = (date) => date ? new Date(date).toLocaleDateString() : new Date().toLocaleDateString();

//  функция добавления ведущих нулей (если число меньше десяти, перед числом добавляем ноль)
export function ZeroFormat(value) {
	if (value < 10) value='0'+value;
	return value;
}
// получить дату и время в привычном формате 01.08.2013 01:41:37 из utc
export function DateFormatNormal(data, zone) {
    let local_offset = new Date((data)*1000).getTimezoneOffset() / 60;
	const offset = local_offset;
	let t_date = new Date((data)*1000);
	let l_offset = t_date.getTimezoneOffset();
	let new_date = zone ? ((data + l_offset * 60 + offset) * 1000) : t_date
	let day = ZeroFormat(new_date.getDate());
	let month = ZeroFormat(new_date.getMonth()+1);
	let year = new_date.getFullYear();
	let hours = ZeroFormat(new_date.getHours());
	let minutes = ZeroFormat(new_date.getMinutes());
	let seconds = ZeroFormat(new_date.getSeconds());
	return day+"."+month+"."+year+" "+hours+":"+minutes+":"+seconds;
}

export function DateFormat(data, zone) {
	const time = data.time;
	const offset = data.offset;
	let t_date = new Date((time)*1000);
	let l_offset = t_date.getTimezoneOffset();
	return zone ? ((time + l_offset * 60 + offset) * 1000) : t_date;
}
export function dateToUnixtime(date) {
	return Math.floor(date / 1000);
}
export function calculationDays(date, count) {
    var result = new Date(date);
    result.setDate(result.getDate() - count);
    return result.getTime();
}
export function getPeriodDate (id) {
    const NOW_DATE = Date.now(); 
    if (id === PERIOD_TODAY) {
        let today = new Date(NOW_DATE);
        today.setHours(0, 0, 0, 0);
        return {
            start_date: dateToUnixtime(today.getTime()),
            finish_date: dateToUnixtime(NOW_DATE)
        }
    }
    if (id === PERIOD_WEEK) {
        return {
            start_date: dateToUnixtime(calculationDays(NOW_DATE, 7)),
            finish_date: dateToUnixtime(NOW_DATE)
        }
    }
    if (id === PERIOD_MONTH) {
        return {
            start_date: dateToUnixtime(calculationDays(NOW_DATE, 30)),
            finish_date: dateToUnixtime(NOW_DATE)
        }
    }
    if (id === PERIOD_YEAR) {
        return {
            start_date: dateToUnixtime(calculationDays(NOW_DATE, 365)),
            finish_date: dateToUnixtime(NOW_DATE)
        }
    }
}
export function presenceArrayInArray(array, array_2) {
    return array.filter( i=>i.constructor.name == "Array" ).filter( i => JSON.stringify(Array.from(new Set(i)).sort()) == JSON.stringify(Array.from(new Set(array_2)).sort())  ).length > 0
}
export function dataParenthesesInArray(text) {
    let reg = /(?:\(|,)(.+?)(?=,|\))/g;
    let array = [];
    let m;
    while (((m = reg.exec(text)) != null)) {
        array.push(m[1]);
    }
    return array
}
export function sumArray(arr) {
    return arr.reduce((p, a) => p + a, 0);
}
//убираем лишние пробелы
export function ReplaceSpaces(text) {
	let t = text;
    t = t.replace(/ /g,"");
    return t;
}
export function randomNumberColor() {
    const number = Math.floor(Math.random() * (Math.random() * 360))
    return number;
}
export function generateHSL(number) {
    return `hsla(${number}, ${HSL_SAT}%, ${HSL_LIGHT}%, 1)`;
}
export function convertHSLToHEX(hue, sat=HSL_SAT, light=HSL_LIGHT) {
    light /= 100;
    const a = sat * Math.min(light, 1 - light) / 100;
    const f = n => {
      const k = (n + hue / 30) % 12;
      const color = light - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

export function sortABC(arr, item) {
    return arr.sort((a, b) => a[item].localeCompare(b[item]));
}
