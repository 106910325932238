import './Tabs.scss'
import React, {PureComponent} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { BEM } from '../../../functions/globalFunctions'
import { ARRAY_SUB_REPORTS, SUB_REPORTS_ID } from '../../../constants/variables'
import { Link } from 'react-router-dom'
import { setEnvironment } from '../../../actions/actions';
import { getSubMenu } from '../../../localStorage';

class Tabs extends PureComponent {
	static propTypes = {
		items: PropTypes.array,
		active: PropTypes.number,
		onChange: PropTypes.func,
		className: PropTypes.string

	}
	static defaultProps = {
		items: [],
		active: 0,
		className: 'Tabs'
	}

	constructor(props) {
	  super(props);
		this.state = {
			activeStyle: {},
			active: this.props.active,
			show_sub_menu: false,
			active_sub: null,
			hover_menu: null
		}
		this.handleSubmenuOver = this.handleSubmenuOver.bind(this);
		this.handleSubmenuOut = this.handleSubmenuOut.bind(this);
		this.buttons = {};
		this.slide_timer = null;
	}
	componentDidUpdate(prevProps) {
		if (this.props.items !== prevProps.items) {
			this.slideToActive(this.state.active);
		}
		if (this.props.active !== prevProps.active) {
			this.setState({
				active: this.props.active
			});
		}
	}
	componentDidMount() {
		this.slideToActive(this.state.active);
		const menu_sub = getSubMenu()
		this.setState({
			active_sub: menu_sub ? SUB_REPORTS_ID[menu_sub] : null
		})
	}
	componentWillUnmount() {
		this.slide_timer && clearTimeout(this.slide_timer);
	}
	slideToActive(active) {
		if (this.props.tabsMenu) {
			this.slide_timer = setTimeout(() => {
				this.setState({
					activeStyle: {
						width: this.buttons[active] ? (this.buttons[active].offsetWidth) + 'px' : 0,
						left: this.buttons[active] ? (this.buttons[active].offsetLeft - 1) + 'px' : 0,
						marginTop: '5px'
					}
				});
			}, 300);
		} else {
			this.slide_timer = setTimeout(() => {
				this.setState({
					activeStyle: {
						width: this.buttons[active] ? (this.buttons[active].offsetWidth) + 'px' : 0,
						left: this.buttons[active] ? (this.buttons[active].offsetLeft - 1) + 'px' : 0
					}
				});
			}, 300);
		}
	}
	onClick(active, data) {
		console.log('onClick', active, this.state.active_sub, data);
		if (this.state.active_sub !== null && active === this.state.active) return ;
		const SUB = data.sub && data.sub.length > 0;
		const menu_sub = getSubMenu()
		this.setState({
			active,
			show_sub_menu: SUB
		});
		this.slideToActive(active);
		this.props.onChange(active);
	}
	onClickSub(active, key_parent) {
		console.log('onClickSub', active);
		this.setState({
			active: key_parent,
			active_sub: active
		});
		console.log('onClickSub22', ARRAY_SUB_REPORTS[active], active);
		this.props.onChange(key_parent, ARRAY_SUB_REPORTS[active]);
	}
	handleSubmenuOver(key) {
		console.log('handleSubmenuOver');
		this.setState({
			show_sub_menu: true,
			// active_sub: (this.state.active_sub === null ? 0 : this.state.active_sub),
			hover_menu: key
		});
	}
	handleSubmenuOut(key) {
		console.log('handleSubmenuOut');
		this.setState({
			show_sub_menu: false,
			hover_menu: null
		});
	}
  render() {
	const {className:c, items} = this.props;
	const {show_sub_menu, active_sub, hover_menu} = this.state;
    return items.length > 0 ? (
			<div className={c}>
				<span className={BEM(c, 'active-bg')} style={this.state.activeStyle}></span>
					{items.map((i,k) => {
						const ACTIVE = this.state.active === k;
						const SUB = i.sub && i.sub.length > 0;
						return i === undefined ? null : (			
								<div key={k}
									ref={el => this.buttons[k] = el}
									className={BEM(c, 'item', [ACTIVE ? 'active' : null])}
									onClick={SUB ? () => this.handleSubmenuOver(k) : () => this.onClick(k, i)}
									onMouseEnter={SUB ? () => this.handleSubmenuOver(k) : null}
									onMouseLeave={SUB ? () => this.handleSubmenuOut(k) : null}
								>
									{i.text}
									<div>
									{show_sub_menu && SUB && hover_menu === k && (
									<div className='SubMenu'>
										<ul>
											{i.sub.map((p, n) => {
												return (
													<li key={n} onClick={() => this.onClickSub(n, k)} 
													className={BEM('SubMenu', 'item_sub', [active_sub === n && ACTIVE ? 'active' : null])}>
														{p.text}
													</li>
												)
											})}
										</ul>
									</div>
									)}
									</div>
									
								</div>
						);
					})}
			</div>
		) : null;
  }
}
// const mapStateToProps = (state) => {
// 	return { 

// 	};
//   };
  const mapDispatchToProps = (dispatch) => ({
	setEnvironment: (key, data) => dispatch(setEnvironment(key, data)),
  });
  
  export default (connect(null, mapDispatchToProps)(Tabs));