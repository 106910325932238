import './modules.scss'
import React from "react";

const Toggler = ({title, value, handleChange}) => {
  return (
    <div className="Toggler">
      {title && <label className="form-label">
        {title}
      </label>}

      <div>
      <label className="Toggler-label">
	        <input onChange={handleChange} checked={value} type="checkbox"/>
	        <span className="checkbox-Toggler"></span>
        </label>
      </div>
    </div>
  );
};

export default Toggler;