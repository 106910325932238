const Breakpoints = {
  XSmall: '(max-width: 599px)',
  Small: '(min-width: 600px) and (max-width: 959px)',
  Medium: '(min-width: 960px) and (max-width: 1279px)',
  Large: '(min-width: 1280px) and (max-width: 1799px)',
  XLarge: '(min-width: 1800px) and (max-width: 2559px)',
  XXLarge: '(min-width: 2560px)'
};

let handlers = [];
const xSmallMedia = window && window.matchMedia && window.matchMedia(Breakpoints.XSmall);
const smallMedia = window && window.matchMedia && window.matchMedia(Breakpoints.Small);
const mediumMedia = window && window.matchMedia && window.matchMedia(Breakpoints.Medium);
const largeMedia = window && window.matchMedia && window.matchMedia(Breakpoints.Large);
const xlargeMedia = window && window.matchMedia && window.matchMedia(Breakpoints.XLarge);
const xxlargeMedia = window && window.matchMedia && window.matchMedia(Breakpoints.XXLarge);

// [xSmallMedia, smallMedia, mediumMedia, largeMedia].forEach(media => {
//   media.addEventListener((e) => {
//     e.matches && handlers.forEach(handler => handler());
//   });
// });

export const sizes = () => {
  return {
    'xsmall': xSmallMedia && xSmallMedia.matches,
    'small': smallMedia && smallMedia.matches,
    'medium': mediumMedia && mediumMedia.matches,
    'large': largeMedia && largeMedia.matches,
    'xlarge': xlargeMedia && xlargeMedia.matches,
    'xxlarge': xxlargeMedia && xxlargeMedia.matches
  };
};

export const subscribe = handler => handlers.push(handler);

export const unsubscribe = handler => {
  handlers = handlers.filter(item => item !== handler);
};
