import React from "react";
import { BEM } from "../../functions/globalFunctions";

const LogoBlock = ({url}) => {
  return (
    <span><img className={BEM("App-logo")} src={url}/></span>
  );
};

export default LogoBlock;
