import React from "react";

const Button = ({type, action, title, style}) => {
  return (
    <button
      style={style}
      className={`btn btn__${type} `}
      onClick={action}
    >
      {title}
    </button>
  );
};

export default Button;
