export const LANG = '_web-stat-lang';
export const SUBMENU = 'sub-menu';
export const THEME_APP = 'theme-app'

export function setItem(key, value) {
	return window.localStorage.setItem(key, value);
}
export function getItem(key) {
	return window.localStorage.getItem(key);
}
export function removeItem(key) {
	return window.localStorage.removeItem(key);
}


export function getLanguage() {
	return JSON.parse(getItem(LANG));
}
export function setLanguage(data = {}) {
	return setItem(LANG, JSON.stringify(data));
}
export function resetLanguage() {
	return removeItem(LANG);
}

export function getThemeApp() {
	return JSON.parse(getItem(THEME_APP));
}
export function setThemeApp(data = {}) {
	return setItem(THEME_APP, JSON.stringify(data));
}
export function resetThemeApp() {
	return removeItem(THEME_APP);
}

export function getSubMenu() {
	return JSON.parse(getItem(SUBMENU));
}
export function setSubMenu(value) {
	return setItem(SUBMENU, JSON.stringify(value));
}
export function resetSubMenu() {
	return removeItem(SUBMENU);
}