import { request } from "../actions/request";

const APP_SERVER = {
    'default': 'http://10.0.0.51:8889',
    'test': 'http://10.0.1.187:8888',
    'test2': 'http://10.0.1.187:8889',
    'test3': 'http://10.0.0.51:8890'
}
let DOMEN = APP_SERVER['test3'];

export function getAppDomen() {
    window.APP_DOMEN = process.env.NODE_ENV === 'production' ? '' : DOMEN;
}
export function getAppServer(role) {
      const PATH_API = `/${role}/api`;
      const API = process.env.NODE_ENV === 'production' ? PATH_API : DOMEN + PATH_API;
      window.APP_SERVER = API;
}
export function getAppToken() {
    return request('/token', undefined, 'GET', 'text').then(d => {
        if (d && d.status === 200) {
            window.APP_ACCESS_TOKEN = d.data;
            return true;
        } else {
            return false;
        }
    })
}
export function deleteAppToken() {
    return request('/token', undefined, 'DELETE')
}
export function knockAction() {
    return request('/knock', undefined, 'GET', true)
}