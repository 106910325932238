import {
	SET_IN_DATABASE_BY_PATH,
  APPEND_IN_DATABASE_BY_PATH,
  UPDATE_IN_DATABASE_BY_PATH,
  REMOVE_IN_DATABASE_BY_PATH,
  RESET_DATABASE,
  START_FETCH,
  STOP_FETCH,
  RESET_FETCHED,
  SET_AUTH_INFO,
  SET_ENVIRONMENT,
  RESET_ENVIRONMENT,
  SET_DISPLAY_MODAL,
  RESET_MODALS,
  SUCCESSFUL_EXECUTION,
  RESET_EXECUTION,
  SET_ENVIRONMENT_ERROR,
  RESET_ENVIRONMENT_ERROR,
  RESET_USER
} from '../actions/actionsTypes';
import { THEME_MAIN } from '../constants/variables';
import {
  assignToPathFromObjectToValue,
	appendToPathFromObjectToValue,
  updateToPathFromObject,
  removeKeyFromPathInObject
} from "../helpers";

const INITIAL_STATE_DATABASE = {
}
export function database(state = INITIAL_STATE_DATABASE, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
    case SET_IN_DATABASE_BY_PATH:
        return Object.assign({}, state, assignToPathFromObjectToValue(new_state, action.path, action.data));
    case APPEND_IN_DATABASE_BY_PATH:
        return Object.assign({}, state, appendToPathFromObjectToValue(new_state, action.path, action.data, action.options));
    case UPDATE_IN_DATABASE_BY_PATH:
      return Object.assign({}, state, updateToPathFromObject(new_state, action.data, action.path, action.item, action.index));
    case REMOVE_IN_DATABASE_BY_PATH:
      return Object.assign({}, state, removeKeyFromPathInObject(new_state, action.path, action.key));
    case RESET_DATABASE:
      return new_state.database = INITIAL_STATE_DATABASE

      default:
        return state;
    }
};

const INITIAL_STATE_FETCHED = {}
export function fetched (state = INITIAL_STATE_FETCHED, action) {
  let new_state = Object.assign({}, state);
  switch (action.type) {
    case START_FETCH:
          return Object.assign( {}, state, { [ action.key ]: true } );
    case STOP_FETCH:
          return Object.assign( {}, state, { [ action.key ]: false } );
    case RESET_FETCHED:
      return new_state.fetched = INITIAL_STATE_FETCHED

    default:
      return state;
  }
};

const INITIAL_STATE_USER = {
}
export function userInfo(state = INITIAL_STATE_USER, action) {
  let new_state = Object.assign({}, state);
  switch (action.type) {
    case SET_AUTH_INFO:
      return {
          role: action.role,
          info: action.info
      };
    case RESET_USER:
    return new_state.user = INITIAL_STATE_USER

      default:
      return state;
  }
}

const INITIAL_STATE_ENVIRONMENT = {
  lang: 'RU',
  theme: THEME_MAIN,
  media: {name: 'medium'}
}
export function environment(state = INITIAL_STATE_ENVIRONMENT, action) {
  let new_state = Object.assign({}, state);
    switch (action.type) {
    case SET_ENVIRONMENT:
      new_state[action.key] = action.data
      return new_state;
    case SET_ENVIRONMENT_ERROR:
      new_state.error_msg = Object.assign({}, new_state.error_msg, action.data)
      return new_state;
    case RESET_ENVIRONMENT_ERROR:
      new_state.error_msg = Object.assign({}, new_state.error_msg, {})
    case RESET_ENVIRONMENT:
      return new_state.environment = INITIAL_STATE_ENVIRONMENT

      default:
        return state;
    }
}

const INITIAL_STATE_EXECUTION = {}
export function execution (state = INITIAL_STATE_EXECUTION, action) {
  let new_state = Object.assign({}, state);
  switch (action.type) {
    case SUCCESSFUL_EXECUTION:
          return Object.assign( {}, state, { [ action.path ]: action.data } );
    case RESET_EXECUTION:
      return new_state.execution = INITIAL_STATE_EXECUTION

    default:
      return state;
  }
};

const INITIAL_STATE_MODALS = {
  user: {},
  sources: {}
}
export function modals (state = INITIAL_STATE_MODALS, action) {
  let new_state = Object.assign({}, state);
  switch (action.type) {
    case SET_DISPLAY_MODAL:
      new_state[action.modal_key] = Object.assign(
        {is_shown: action.is_shown}, 
        action.params,
      );
      return new_state
    case RESET_MODALS:
      return new_state.modals = INITIAL_STATE_MODALS

      default:
      return state;
  }
};