import React from 'react'
import { ANALYTICS, APR, AREA_CHART, AUG, BAR_CHART, CANCELLED, CONCLUDED, DEC, DIVISION_NAME, FEB, INCREMENT, JAN, JULY, JUNE, JURIDICAL_PERSON, MAIN, MAP, MAR, MAY, NOV, OCT, PHYSICAL_PERSON, PIE_CHART, SEPT, SOURCES, TOTAL, USERS, NAME, FIO, LOGIN, REPORTS, PASSWORD, USERNAME, AUTHORIZATION_PAGE, ADMIN_PAGE, ADDRESS, AUTHORIZATION_KEY, NEW_PASSWORD, DOGNUM, GLOBAL } from '../constants/variables';

export const TRANSL = {
    'UZ': {
        name_system: 'Veb-statistika Pritok-A',
        admin: 'Administrator',
        menu: {
            [GLOBAL]: 'Sozlamalar',
            [USERS]: 'Foydalanuvchilar',
            [SOURCES]: 'Manbalar',
            [MAIN]: 'Axborot',
            [MAP]: 'Xarita',
            [ANALYTICS]: 'Tahlil'
        },
        page: {
            [USERS]: {

            },
            [SOURCES]: {

            },
            [MAIN]: {
                [AREA_CHART]: {
                    h1: 'So`nggi 6 oy ichida shartnomalar bo`yicha xizmatlar sonini o`zgartirish',
                    h1_2: 'So`nggi 6 oy ichida shartnomalar sonining o`zgarishi'
                },
                [BAR_CHART]: {
                    h1: 'So`nggi 6 oy ichida shartnomalar bo`yicha xizmatlar sonini o`zgartirish',
                    h1_2: 'So`nggi 6 oy ichida shartnomalar sonining o`zgarishi'
                },
                [PIE_CHART]: {
                    h1: 'Shartnomalar bo`yicha xizmatlar soni',
                    h1_2: 'Shartnomalar soni',
                    columns: {}
                },
            },
            [MAP]: {
                title_chema: 'Himoyalangan ob`ektlarga o`rnatilgan qurilmalar soni (hududlar bo`yicha)',
                title_map: 'Himoyalangan ob`ektlarning asboblarini o`rnatish joylari',
                go_map: 'Xaritaga o`tish',
                go_scheme: 'Sxemaga o`ting'
            },
            [ANALYTICS]: {
                table_services: {
                    h1: 'Change in the number of services under contracts',
                    h1_2: 'Shartnomalar sonini o`zgartirish'
                },
                table_cards: {
                    h1: 'Shartnomalar bo`yicha kartalar ro`yxati'
                },
                filter: {
                    show_pj: 'Jismoniy va yuridik shaxslarga bo`linish bilan ko`rsatish'
                }
            },
            [AUTHORIZATION_PAGE]: {
                error: {
                    no_login: 'Foydalanuvchi nomini ko`rsating',
                    no_password: 'Parolni kiriting',
                    failed_to_log: 'Tizimga kirish imkoni bo`lmadi'
                }
            },
            [ADMIN_PAGE]: {
                title_del_user: 'Foydalanuvchini o`chirish',
                title_del_source: 'Manba olib tashlash',
                del_user: 'Foydalanuvchini o`chirish',
                del_source: 'Manba o`chirish',
                error: {
                    no_url: 'Url manzilini ko`rsatish kerak',
                    no_title: 'Ismni ko`rsatish kerak',
                } 
            }
        },
        month: {
            [JAN]: 'Yanvar',
            [FEB]: 'Fevral',
            [MAR]: 'Mart',
            [APR]: 'Aprel',
            [MAY]: 'May',
            [JUNE]: 'Iyun',
            [JULY]: 'Iyul',
            [AUG]: 'Avgust',
            [SEPT]: 'Sentyabr',
            [OCT]: 'Oktyabr',
            [NOV]: 'Noyabr',
            [DEC]: 'Dekabr'
        },
        contract_form: 'Shartnoma shakli',
        custnum: 'Masofadan boshqarish raqami',
        name_card: 'Nomi',
        name_org: 'Tashkilot nomi',
        address_card: 'Manzili',
        total_services: 'Jami xizmatlar',
        total_devices: 'Jami qurilmalar',
        count: 'Miqdori',
        count_division: 'birlik bo`yicha',
        count_region: 'mintaqa bo`yicha',
        data_load: 'Ma`lumotlar yuklanadi',
        data_load_error:'Ma`lumotlarni yuklab olish xatosi',
        no_data: 'Malumotlar yo`q',
        exit: 'Chiqish',
        actions: 'Harakatlar',
        edit: 'Tahrirlash',
        delete: 'Olib tashlash',
        personal_info:'Shaxsiy ma`lumotlar',
        new_source: 'Yangi manba',
        new_user: 'Yangi foydalanuvchi',
        add_source: 'Manba qo`shing',
        add_user: 'Foydalanuvchi qo`shish',
        logo: 'Logotip',
        load: 'Yuklash',
        change: 'O`zgartirish',
        update: 'Yangilash',
        theme: 'Interfeys mavzusi',
        authorization: 'Avtorizatsiya',
        sum: 'so`m ',
        [TOTAL]: 'Jami',
        [DOGNUM]: 'Shartnoma raqami',
        [DIVISION_NAME]: 'Birlik',
        [PHYSICAL_PERSON]: 'Jismoniy shaxslar',
        [JURIDICAL_PERSON]: 'Yuridik shaxslar',
        [CONCLUDED]: 'Xulosa',
        [CANCELLED]: 'Bekor qilindi',
        [INCREMENT]: 'O`sish',
        [NAME]: 'Naimenavanie',
        [ADDRESS]: 'Ulanish manzili',
        [FIO]: 'FIO',
        [LOGIN]: 'Kirish',
        [USERNAME]: 'Foydalanuvchi nomi',
        [PASSWORD]: 'Parol',
        [NEW_PASSWORD]: 'Yangi parol',
        [REPORTS]: 'Hisobotlar',
        [SOURCES]: 'Manbalar',
        [AUTHORIZATION_KEY]: 'Avtorizatsiya kaliti',
        start_slide: 'Slayd-shouni ishga tushiring',
        stop_slide: 'Slayd namoyishini to`xtating',
        region: 'Viloyat',
        scheme_loc: 'Erning diagrammasi',
        date_time: 'Sana va vaqt',
        type_item: 'Turi',
        enter: 'Kirish',
        save: 'Saqlash',
        cancel: 'Bekor qilish',
        yes: 'Ha',
        no: 'Yo`q',
        employ: 'Qo`llash',
        reset: 'Qayta tiklash',
        close: 'Yopish',
        filter: 'Filtr',
        attention: 'Diqqat',
        date_of_data_update: 'Ma`lumotlarni yangilash sanasi',
        retry_authorization: 'Takrorlash uchun sahifani yangilang',
        error: {
            unauthorized: 'Tizimga kirish uchun avtorizatsiya zarur',
            forbidden: 'Tizimga kirish taqiqlanadi',
            internal_server_error: 'Ichki server xatosi. Ishlab chiquvchilarga murojaat qiling',
            server_error: 'Server vaqtincha mavjud emas. Keyinchalik qayta urinib ko`ring yoki ishlab chiquvchilarga murojaat qiling',
            econnaborted: 'Ulanishning kutish vaqti tugadi',
            failed_execute: 'So`rovni bajarish mumkin emas edi. Keyinchalik qayta urinib ko`ring yoki ishlab chiquvchilarga murojaat qiling',
            user_exists: 'Bunday foydalanuvchi allaqachon mavjud',
            incorrect_data: 'Noto`g`ri ma`lumotlar',
            fill_field: 'Kerakli maydonlarni to`ldiring',
            fix_field: 'Qizil rangda ko`rsatilgan maydonlarni to`g`rilang',
            color_exists: 'Tanlangan rang allaqachon boshqa manbada saqlangan',
            add_origin: 'Manba yaratish mumkin emas, chunki erning sxemasi tanlanmagan'
        },
        war: {
            change_sheme_edit_origin: 'Ma`lumotlarni to`g`ri ko`rsatish uchun siz manbalardagi hududlarni tahrirlashingiz kerak'
        }
    },
    'RU': {
        name_system: 'Веб-статистика Приток-А',
        admin: 'Администратор',
        menu: {
            [GLOBAL]: 'Настройки',
            [USERS]: 'Пользователи',
            [SOURCES]: 'Источники',
            [MAIN]: 'Сведения',
            [MAP]: 'Карта',
            [ANALYTICS]: 'Аналитика'
        },
        page: {
            [USERS]: {

            },
            [SOURCES]: {

            },
            [MAIN]: {
                [AREA_CHART]: {
                    h1: 'Изменение количества услуг по договорам за последние 6 месяцев',
                    h1_2: 'Изменение количества договоров за последние 6 месяцев'
                },
                [BAR_CHART]: {
                    h1: 'Изменение количества услуг по договорам за последние 6 месяцев',
                    h1_2: 'Изменение количества договоров за последние 6 месяцев'
                },
                [PIE_CHART]: {
                    h1: 'Количество услуг по договорам',
                    h1_2: 'Количество договоров',
                    columns: {}
                },
            },
            [MAP]: {
                title_chema: 'Количество приборов на охраняемых объектах (по регионам)',
                title_map: 'Места установок приборов охраняемых объектов',
                go_map: 'Перейти к карте',
                go_scheme: 'Перейти к схеме'
            },
            [ANALYTICS]: {
                table_services: {
                    h1: 'Изменение количества услуг по договорам',
                    h1_2: 'Изменение количества договоров'
                },
                table_cards: {
                    h1: 'Список карточек по договорам'
                },
                filter: {
                    show_pj: 'Показать с разделением по физическим и юридическим лицам'
                }
            },
            [AUTHORIZATION_PAGE]: {
                error: {
                    no_login: 'Укажите имя пользователя',
                    no_password: 'Введите пароль',
                    failed_to_log: 'Не удалось войти в систему'
                }
            },
            [ADMIN_PAGE]: {
                title_del_user: 'Удаление пользователя',
                title_del_source: 'Удаление источника',
                del_user: 'Удалить пользователя',
                del_source: 'Удалить источник',
                error: {
                    no_url: 'Необходимо указать url',
                    no_title: 'Необходимо указать название',
                } 
            }

        },
        month: {
            [JAN]: 'Январь',
            [FEB]: 'Февраль',
            [MAR]: 'Март',
            [APR]: 'Апрель',
            [MAY]: 'Май',
            [JUNE]: 'Июнь',
            [JULY]: 'Июль',
            [AUG]: 'Август',
            [SEPT]: 'Сентябрь',
            [OCT]: 'Октябрь',
            [NOV]: 'Ноябрь',
            [DEC]: 'Декабрь'
        },
        contract_form: 'Форма договора',
        custnum: 'Пультовый номер',
        name_org: 'Наименование организации',
        name_card: 'Hаименование',
        address_card: 'Адрес',
        total_services: 'Всего услуг',
        total_devices: 'Всего приборов',
        count: 'Количество',
        count_division: 'по подразделению',
        count_region: 'по региону',
        data_load: 'Данные загружаются',
        data_load_error:'Ошибка загрузки данных',
        no_data: 'Нет данных',
        exit: 'Выйти',
        actions: 'Действия',
        edit: 'Редактировать',
        delete: 'Удалить',
        personal_info: 'Личная информация',
        new_source: 'Новый источник',
        new_user: 'Новый пользователь',
        add_source: 'Добавить источник',
        add_user: 'Добавить пользователя',
        logo: 'Логотип',
        load: 'Загрузить',
        change: 'Изменить',
        update: 'Обновить',
        theme: 'Тема интерфейса',
        authorization: 'Авторизация',
        [DOGNUM]: 'Номер договора',
        sum: 'Итого',
        [TOTAL]: 'Всего',
        [DIVISION_NAME]: 'Подразделение',
        [PHYSICAL_PERSON]: 'Физические лица',
        [JURIDICAL_PERSON]: 'Юридические лица',
        [CONCLUDED]: 'Заключено',
        [CANCELLED]: 'Расторгнуто',
        [INCREMENT]: 'Прирост',
        [NAME]: 'Наименование',
        [ADDRESS]: 'Адрес подключения',
        [FIO]: 'ФИО',
        [LOGIN]: 'Логин',
        [USERNAME]: 'Имя пользователя',
        [PASSWORD]: 'Пароль',
        [NEW_PASSWORD]: 'Новый пароль',
        [REPORTS]: 'Отчеты',
        [SOURCES]: 'Источники',
        [AUTHORIZATION_KEY]: 'Ключ авторизации',
        start_slide: 'Запустить показ слайдов',
        stop_slide: 'Остановить показ слайдов',
        region: 'Регион',
        scheme_loc: 'Схема местности',
        date_time: 'Дата и время',
        type_item: 'Тип',
        enter: 'Войти',
        save: 'Сохранить',
        cancel: 'Отмена',
        yes: 'Да',
        no: 'Нет',
        employ: 'Применить',
        reset: 'Сбросить',
        close: 'Закрыть',
        filter: 'Фильтр',
        attention: 'Внимание',
        date_of_data_update: 'Дата обновления данных',
        retry_authorization: 'Для повтора обновите страницу',
        error: {
            unauthorized: 'Для доступа в систему необходима авторизация',
            forbidden: 'Доступ в систему запрещен',
            internal_server_error: 'Внутренняя ошибка сервера. Обратитесь к разработчикам',
            server_error: 'Сервер временно недоступен. Повторите попытку позже или обратитесь к разработчикам',
            econnaborted: 'Время ожидания соединения истекло',
            failed_execute: 'Не удалось выполнить запрос. Повторите попытку позже или обратитесь к разработчикам',
            user_exists: 'Такой пользователь уже существует',
            incorrect_data: 'Некорректные данные',
            fill_field: 'Заполните обязательные поля',
            fix_field: 'Исправьте выделенные красным цветом поля',
            color_exists: 'Выбранный цвет уже сохранен в другом источнике',
            add_origin: 'Невозможно создать источник, так как не выбрана схема местности'
        },
        war: {
            change_sheme_edit_origin: 'Для корректного отображения данных необходимо отредактировать регионы в источниках'
        }
    }
}
export function getLang(lang = '') {
    return TRANSL[lang]
}
export const LanguageContext = React.createContext({ 
    lang: 'RU',
    currentData: TRANSL['RU'],
    switchLang: () => {}, 
});

export const LanguageConsumer = LanguageContext.Consumer;