export const getPathFromObject = (obj, path, defaultValue) => {
	let keys = path.split('.');
	let result = getPathFromObjectByArray(obj, keys);
	// console.log('getPathFromObject', obj, path, keys, result);
	return result === undefined ? defaultValue : result;
}
export const getPathFromObjectByArray = (obj, path) => {
	let key = path.pop();
	let result = path.length !== 0 ? getPathFromObjectByArray(obj, path) : obj;
	return result ? result[key] : result;
}
export const assignToPathFromObjectToValue = (obj, path, value, action, options) => {
	// console.log(obj, path, value, action, options);
	let keys = path.split('.');
	return findPathAndAssign(obj, keys, value, action, options);
}
export const findPathAndAssign = (obj, keys, value, action, options) => {
	if (keys.length === 0) {
		return action ? action(obj, value, options) : value;
	}
	let key = keys.shift();
	let curr = Array.isArray(obj[key]) ? [...obj[key]] : Object.assign({}, obj[key]);
	return Object.assign(obj, {[key]: findPathAndAssign(curr, keys, value, action, options)});
}
export const removeKeyFromPathInObject = (obj, path, key) => {
	return assignToPathFromObjectToValue(obj, path, key, removeItem);
}
// export const appendInArray = (arr, value, {deep_level = 0} = {}) => {
// 	console.log(arr, value);
// 	console.log(typeof(value));
// 	console.log(Array.isArray(value));
// 	console.log(Array.isArray(arr));
// 	console.log(arr[0]);
// 	// let lev = false;
// 	// for (let key in arr) {
// 	// 	console.log(key)
// 	// 	if (typeof (arr[key]) == "object") {
// 	// 		console.log('есть вложенность');
// 	// 		lev = true;
// 	// 	} else {
// 	// 		lev = false;
// 	// 	}
// 	//   }
// 	//   console.log(lev);
// 	//   return (Array.isArray(arr) || Array.isArray(value)) ? [...arr, value] : [value];
// 	return appendInObject(arr, value, {deep_level});
	
// }
export const appendInArray = (arr, value, options) => {
	// console.log('appendInArray', arr, value, options);
	// let lev = false;
	// for (let key in arr) {
	// 	console.log(key)
	// 	if (typeof (arr[key]) == "object") {
	// 		console.log('есть вложенность');
	// 		lev = true;
	// 	} else {
	// 		lev = false;
	// 	}
	//   }
	return options && options.unite ? ([...arr, ...value]) : ((options && (options.deep_level > 0 || (options && options.act === 'appendInObj'))) ? (appendInObject(arr, value, options && options.deep_level ? {deep_level: options.deep_level} : {})) : (
		(Array.isArray(arr) && Array.isArray(value)) ? [...arr, ...value] : Array.isArray(arr) ? [...arr, value] : Array.isArray(value) ? value : [value])
	)				
}
export const appendInObject = (obj, value, {deep_level} = {}) => {
	if (deep_level > 0) {
		let result = Object.assign({}, obj);
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (result.hasOwnProperty(key)) {
					result[key] = appendInArray(result[key], value[key], {deep_level: deep_level - 1})
				} else {
					result[key] = value[key]
				}
			}
		}
		return result;
	} else {
		return obj ? Object.assign({}, obj, value) : value;
	}
}

export const appendToPathFromObjectToValue = (obj, path, value, options) => {
	// if (arr === undefined) return assignToPathFromObjectToValue(obj, path, value);
	return assignToPathFromObjectToValue(obj, path, value, appendInArray, options);
}

export const removeItem = (obj, key) => {
	// console.log('removeItem', obj, key);
	if (Array.isArray(obj)) {
		if (obj.length === 1) {
			obj = []
		} else {
			obj.splice(key, 1);
		}
	} else if (typeof obj === 'object') {
		delete obj[key]
	}
	// console.log('removeItemobj', obj);
	return obj;
}

//Обновление элемента
export const updateToPathFromObject = (new_state, obj, action_path, action_item, action_index) => {
	const new_array = [];
	const new_obj = obj === undefined ? {} : JSON.parse(JSON.stringify(obj));
	if (Array.isArray(obj)) {
		obj.map((item, index) => {
			if (typeof item === 'object') {
				if (index !== action_index) {
					return new_array.push(item);
				  }
				  return new_array.push({
					...item,
					...action_item
				  });
			} else {
				if (index !== action_index) {
					return new_array.push(item);
				  }
				  return new_array[index] = action_item
			}
		  })
	}
	const finish_obj = Array.isArray(obj) ? new_array : Object.assign({}, new_obj, action_item);
	// console.log('updateToPathFromObject', finish_obj)
	return assignToPathFromObjectToValue(new_state, action_path, finish_obj);
}

export function makeIndexKey(filters_array = [], filters = {}) {
	// console.log(filters_array, filters);
	const filter_path = filters_array.map(i => filters[i] ? `${i}_${filters[i]}` : null).filter(i => i !== null).join('+');
	// console.log(filter_path);
	//точки заменяем на тире
	const f_filter_path = filter_path.includes('.') ? filter_path.replace('.', '_') : filter_path;
	return (filter_path ? `${f_filter_path}` : 'all');
}

// пример list = [{id: 1, value: 'fvfv'}, {id: 2, value: 'fvfv'}]
//результат {1: {id: 1, value: 'fvfv'}, 2: {id: 2, value: 'fvfv'}}
export function buildIndex(list, key = 'id') {
	let index = {};
	list.forEach(item => index[item[key]] = item);
	return index;
}
//результат {1: 0, 2: 1}
export function buildIndexOfKeys(list, key = 'id') {
	let index = {};
	list.forEach((item, position) => index[item[key]] = position);
	return index;
}
//результат {1: 'fvfv', 2: 'hhh'}
export function buildKeyValueIndex(list = [], key_name = 'id', value_name = 'value') {
	let index = {};
	list.forEach(item => index[item[key_name]] = item[value_name]);
	return index;
}