import { combineReducers } from 'redux'
import {database, fetched, userInfo, environment, modals, execution} from './mainReducer';

const rootReducer =combineReducers({
    database,
    fetched,
	user: userInfo,
    env: environment,
    modals,
    execution
})

export default rootReducer;