import {Component} from 'react'
import ReactDOM from 'react-dom'
import { canUseDOM } from '../../../functions/globalFunctions';

// const modalRoot = document.getElementById( 'modal' );
export default class Portal extends Component {
	constructor(props) {
	  super(props);
	  this.container = canUseDOM() ? document.createElement('div') : null;
	}
	componentDidMount() {
		this.portal = document.getElementById('portals');
		this.portal && this.portal.appendChild(this.container);
	}
	componentWillUnmount() {
		this.portal.removeChild(this.container);
	}
  render() {
    return canUseDOM() ? ReactDOM.createPortal(
			this.props.children,
			this.container
		) : null;
  }
}
